import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './circularprogress.css'
import axios from "axios";
import React, {useState, useEffect} from "react";
import baseURL from '../../config';
import { useTranslation } from "react-i18next";


const ProgressProvider = ({ valueStart, valueEnd, children }) => {
  const [value, setValue] = useState(valueStart);
  useEffect(() => {
    setValue(valueEnd);
  }, [valueEnd]);

  return children(value);
};

const CircularProgress = () =>{
    const [intellettualiValue, setIntellettualiValue] = useState(null);
    const [viteValue, setViteValue] = useState(null);
    const [familiariValue, setFamiliariValue] = useState(null);
    const [mappeValue, setMappeValue] = useState(null);
    const [fotoValue, setFotoValue] = useState(null);
    const { t, i18n } = useTranslation();

    const uniqueMaps = (list, keyFn) => list.reduce((resultSet, item) => 
        resultSet.add(typeof keyFn ==="string" ? item[keyFn] : keyFn(item)), new Set).size/2;

    useEffect(() => {
        const fetchData = async () =>{
            await axios.get(baseURL+"/intellettuali/count").then((response) => {
                setIntellettualiValue(response.data.count);
            });
            await axios.get(baseURL+`/schede/count?filter={"where": {"and" : [
                                                                    {"lingua" : "1"}, 
                                                                    {"pubblico" : "true"}]
                                                                }}`).then((response) => {
                setViteValue(Math.floor(response.data.count/2));
            });
            await axios.get(baseURL+`/familiari/count`).then((response) => {
                setFamiliariValue(response.data.count);
            });
            await axios.get(baseURL+`/mobilita/`).then((response) => {
                setMappeValue(Math.floor(uniqueMaps(response.data, 'idScheda')));
            });
            await axios.get(baseURL+`/immagini/count`).then((response) => {
                console.log(response)
                const counter = response.data.count/2 % 1 !=0  ? Math.floor(response.data.count/2+1) : response.data.count/2
                console.log(counter)
                setFotoValue(190 + counter);
            });
            
          }
          fetchData();
    },[]);


    return (
        <div className='int__circular-progressbar'>
            <div className='int__circular-progressbar-element'>
                <ProgressProvider valueStart={10} valueEnd={intellettualiValue}>
                    {value => <CircularProgressbar 
                    value={value}
                    strokeWidth={10}
                    circleRatio={0.75}
                    text={`${value}`}
                    styles={{
                        // Customize the path, i.e. the "completed progress"
                        path: {
                            // Path color
                            stroke: `rgba(154, 20, 19, ${value / 400})`,
                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                            strokeLinecap: 'round',
                            // Customize transition animation
                            transition: 'stroke-dashoffset 1.5s ease 0s',
                            // Rotate the path
                            transform: 'rotate(0.625turn)',
                            transformOrigin: 'center center',
                        },
                        // Customize the circle behind the path, i.e. the "total progress"
                        trail: {
                            // Trail color
                            stroke: '#d6d6d6',
                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                            strokeLinecap: 'round',
                            // Rotate the trail
                            transform: 'rotate(0.625turn)',
                            transformOrigin: 'center center',
                        },
                        // Customize the text
                        text: {
                            fill: 'rgba(154, 20, 19)',
                            fontSize: '25px',
                            fontWeight: '700',
                            fontFamily: "Open Sans"
                        }
                    }} 
                    />}
                </ProgressProvider>
                <div className='int__circular-progressbar-text'>
                    <p>{t("Intellettuali IF")}</p>
                </div>
            </div>
            <div className='int__circular-progressbar-element'>
                <ProgressProvider valueStart={10} valueEnd={viteValue}>
                    {value => <CircularProgressbar 
                    value={value}
                    strokeWidth={10}
                    maxValue={intellettualiValue}
                    circleRatio={0.75}
                    text={`${value}`}
                    styles={{
                        // Customize the path, i.e. the "completed progress"
                        path: {
                            // Path color
                            stroke: `rgba(154, 20, 19, ${value / intellettualiValue})`,
                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                            strokeLinecap: 'round',
                            // Customize transition animation
                            transition: 'stroke-dashoffset 1.5s ease 0s',
                            // Rotate the path
                            transform: 'rotate(0.625turn)',
                            transformOrigin: 'center center',
                        },
                        // Customize the circle behind the path, i.e. the "total progress"
                        trail: {
                            // Trail color
                            stroke: '#d6d6d6',
                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                            strokeLinecap: 'round',
                            // Rotate the trail
                            transform: 'rotate(0.625turn)',
                            transformOrigin: 'center center',
                        },
                        // Customize the text
                        text: {
                            fill: 'rgba(154, 20, 19)',
                            fontSize: '25px',
                            fontWeight: '700',
                            fontFamily: "Open Sans"
                        }
                    }} 
                    />}
                </ProgressProvider>
                <div className='int__circular-progressbar-text'>
                    <p>{t("Vite in movimento")}</p>
                </div>
            </div>
            <div className='int__circular-progressbar-element'>
                <ProgressProvider valueStart={10} valueEnd={familiariValue}>
                    {value => <CircularProgressbar 
                    value={value}
                    strokeWidth={10}
                    circleRatio={0.75}
                    text={`${value}`}
                    styles={{
                        // Customize the path, i.e. the "completed progress"
                        path: {
                            // Path color
                            stroke: `rgba(154, 20, 19, ${value / familiariValue})`,
                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                            strokeLinecap: 'round',
                            // Customize transition animation
                            transition: 'stroke-dashoffset 1.5s ease 0s',
                            // Rotate the path
                            transform: 'rotate(0.625turn)',
                            transformOrigin: 'center center',
                        },
                        // Customize the circle behind the path, i.e. the "total progress"
                        trail: {
                            // Trail color
                            stroke: '#d6d6d6',
                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                            strokeLinecap: 'round',
                            // Rotate the trail
                            transform: 'rotate(0.625turn)',
                            transformOrigin: 'center center',
                        },
                        // Customize the text
                        text: {
                            fill: 'rgba(154, 20, 19)',
                            fontSize: '25px',
                            fontWeight: '700',
                            fontFamily: "Open Sans"
                        }
                    }} 
                    />}
                </ProgressProvider>
                <div className='int__circular-progressbar-text'>
                    <p>{t("Familiari")}</p>
                </div>
            </div>
            <div className='int__circular-progressbar-element'>
                <ProgressProvider valueStart={10} valueEnd={mappeValue}>
                    {value => <CircularProgressbar 
                    value={value}
                    maxValue={viteValue}
                    strokeWidth={10}
                    circleRatio={0.75}
                    text={`${value}`}
                    styles={{
                        // Customize the path, i.e. the "completed progress"
                        path: {
                            // Path color
                            stroke: `rgba(154, 20, 19, ${value / mappeValue})`,
                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                            strokeLinecap: 'round',
                            // Customize transition animation
                            transition: 'stroke-dashoffset 1.5s ease 0s',
                            // Rotate the path
                            transform: 'rotate(0.625turn)',
                            transformOrigin: 'center center',
                        },
                        // Customize the circle behind the path, i.e. the "total progress"
                        trail: {
                            // Trail color
                            stroke: '#d6d6d6',
                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                            strokeLinecap: 'round',
                            // Rotate the trail
                            transform: 'rotate(0.625turn)',
                            transformOrigin: 'center center',
                        },
                        // Customize the text
                        text: {
                            fill: 'rgba(154, 20, 19)',
                            fontSize: '25px',
                            fontWeight: '700',
                            fontFamily: "Open Sans"
                        }
                    }} 
                    />}
                </ProgressProvider>
                <div className='int__circular-progressbar-text'>
                    <p>{t("Mappe")}</p>
                </div>
            </div>
            <div className='int__circular-progressbar-element'>
                <ProgressProvider valueStart={10} valueEnd={fotoValue}>
                    {value => <CircularProgressbar 
                    value={value}
                    strokeWidth={10}
                    circleRatio={0.75}
                    text={`${value}`}
                    styles={{
                        // Customize the path, i.e. the "completed progress"
                        path: {
                            // Path color
                            stroke: `rgba(154, 20, 19, ${value / fotoValue})`,
                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                            strokeLinecap: 'round',
                            // Customize transition animation
                            transition: 'stroke-dashoffset 1.5s ease 0s',
                            // Rotate the path
                            transform: 'rotate(0.625turn)',
                            transformOrigin: 'center center',
                        },
                        // Customize the circle behind the path, i.e. the "total progress"
                        trail: {
                            // Trail color
                            stroke: '#d6d6d6',
                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                            strokeLinecap: 'round',
                            // Rotate the trail
                            transform: 'rotate(0.625turn)',
                            transformOrigin: 'center center',
                        },
                        // Customize the text
                        text: {
                            fill: 'rgba(154, 20, 19)',
                            fontSize: '25px',
                            fontWeight: '700',
                            fontFamily: "Open Sans"
                        }
                    }} 
                    />}
                </ProgressProvider>
                <div className='int__circular-progressbar-text'>
                    <p>{t("Fotografie")}</p>
                </div>
            </div>
        </div>
    )
}

export default CircularProgress
import axios from "axios";
import React, {useState, useEffect} from "react";
import './videoAboutUs.css';
import "react-image-gallery/styles/css/image-gallery.css";
import baseURL from '../../../config';
import ReactPlayer from 'react-player'
import { useTranslation } from 'react-i18next';
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";


const VideoAboutUs = (props) => {
    const [videoGallery, setVideoGallery] = useState([]);
    const [runningVideo, setRunningVideo] = useState(null);
    const [showVideoGallery, setShowVideoGallery] = useState(false);

    const compareVideos = (a,b) => {
        if (a.ordine < b.ordine) return -1;
        if (a.ordine > b.ordine) return 1;
        return 0;
    }

    useEffect(() => {
        const videoURL = baseURL+`/video-about-us?filter={"where": {"pubblico" : "true"}}`
        axios.get(videoURL). then((response) => {
            const videos = response.data.map((video) => (
                {
                    url: video.video,
                    descrizione: video.descrizione,
                    ordine: video.ordine,
                    titolo: video.titolo
                }));
                videos.sort(compareVideos);
                setVideoGallery(videos);
        });
    },[])

    const handleClick = (video) => {
        setRunningVideo(video);
        setShowVideoGallery(true);
    };
    
    const handleCloseClick = () => {
        setShowVideoGallery(false);
        window.location.reload();
    };

    if (videoGallery.length === 0) return null;
    
    return(
        <>
        <div className="int__video-about-us-main">
            {(videoGallery.map((video) => (
                <div className="int__video-about-us-title-box" key={"title_box"+video.titolo}> 
                    <div className="int__video-about-us-title" key={"titolo"+video.titolo}>
                        {video.titolo}
                    </div>
                    <div className='int__video-about-us-player-wrapper' key={"player"+video.titolo}>
                        <ReactPlayer url={video.url} light={true} playing={false} width='100%' height='100%' onClick={()=> handleClick(video)}/>
                    </div>
                    {/* <div className="int__video-about-us-descr"> 
                        {video.descrizione}
                    </div> */}
                </div>
            ))) 
            }
        </div>
        {runningVideo && 
            <Dialog
            maxWidth="lg"
                open={showVideoGallery}
                onClose={handleCloseClick}
              >
                <DialogTitle>{runningVideo.titolo}</DialogTitle>
                <DialogContent>
                    <div className="int__video-modal">
                        <ReactPlayer url={runningVideo.url} controls={true}/>
                    </div>
                    <div className="int__video-about-us-modal-descr">
                        {runningVideo.descrizione}
                    </div>                 
                </DialogContent>
                  
                <DialogActions>
                     
                </DialogActions>
            </Dialog>
              }
        </>
    )
}

export default VideoAboutUs
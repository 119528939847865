import React, { memo } from 'react';
import { Handle } from 'reactflow';
import "./styles.css";

export default memo(({ data }) => {
  return (
    <div className="int__big-grey-triangle-node">
      <Handle
        type="target"
        position="top"
        id="input"
        style={{ width:0, height: 0, top: 10, border:"none", backgroundColor: "transparent" }}
      />
      <div
      style={{
        marginTop: "40px",
        marginLeft: "-105px"
      }}>
        <input className="nodrag" type="text" defaultValue={data.label} onChange={event => data.label=event.target.value} style={{textAlign: "center", width:200, fontSize:15, fontWeight:700}}/>
      </div>
      <Handle
        type="target"
        position="left"
        id="input_left"
        style={{  width:0, height:0, border:"none", backgroundColor: "transparent", top: 50, left:-105 }}
      />
      <Handle
        type="source"
        position="right"
        id="output_right"
        style={{  width:0, height:0, border:"none", backgroundColor: "transparent", top: 50, right:-95 }}
      />
      <Handle
        type="source"
        position="bottom"
        id="output"
        style={{ width:0, height: 0, bottom: -65, border:"none", backgroundColor: "transparent" }}
      />
    </div>
  );
});

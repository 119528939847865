import React from "react";
import './relazioni.css';

const Relazioni = (props) => {
    const content = props.content;
    const title = props.title;
    return(
        <div className="int_relazioni-main">
            <div className="int__relazioni-title">
                {title}
            </div>
            <div className="int__relazioni-box">
                <div dangerouslySetInnerHTML={{ __html: content }} className="int__relazioni-text" ></div>
            </div>
        </div>
    )
};

export default Relazioni;



import React, {useEffect, useState, useRef } from 'react';
import './ricercatestuale.css'
import {
    Outlet,
    Link
  } from "react-router-dom";
import axios from 'axios';
import baseURL from '../../config';
import { useTranslation } from "react-i18next";
import Modal from '../../containers/modal/Modal';
import PdfBadge from "../../assets/pdf_badge2.jpg";
import routes from '../..'; 

const compareByIdScheda = (a,b) => {
    if (a.idscheda < b.idscheda) return -1;
    if (a.idscheda > b.idscheda) return 1;
    return 0;
}

const mergeArrayIntellettualiAndPdf = (arr1, arr2) => {
    arr2.sort(compareByIdScheda)
    return arr1.map((item,i)=>{
        if(item.id === arr2[i].idscheda){
            //merging two objects
            return Object.assign({},item,arr2[i])
       }
    })
}

const RicercaTestuale = () => {
    const [actualString, setActualString]  = useState("");
    const [searchString, setSearchString] = useState("");
    const [lang, setLang] = useState(null);
    const [contenutiResult, setContenutiResult] = useState([]);
    const [viteInMovimentoResult, setViteInMovimentoResult] = useState([]);
    /* const [pdfResult, setPdfResult] = useState([]); */

    const { t, i18n } = useTranslation();

    const formRef = useRef();
    
    const compareResultsSchede = (a,b) => {
        if (a.intellettualiRelation.cognome < b.intellettualiRelation.cognome) return -1;
        if (a.intellettualiRelation.cognome > b.intellettualiRelation.cognome) return 1;
        return 0;
    }

    /* const compareResultsPdf = (a,b) => {
        if (a.idscheda < b.idscheda) return -1;
        if (a.idscheda < b.idscheda) return 1;
        return 0;
    } */

    
    useEffect(() => {
        const fetchData = async () => {
            if (searchString !== "") {
                //ricerca su contenuti statici (TRANNE I CONTENUTI CON ID 4,15,8,35,37,31,32,9)
                const lang = i18n.language.substring(0,2) === "it" ? "it" : "en";
                const contenutiURL = baseURL+`/contenuti?filter={"where": {"and" : [
                                                                          {"or" : [{"titolo" :{"regexp": "/${searchString}/i"}},
                                                                                   {"testo" :{"regexp": "/${searchString}/i"}}
                                                                                  ]},
                                                                          {"lingua" : "${lang}"},
                                                                          {"id": { "nin": [4,15,8,20,27,35,37,31,32,28,36,9]}}
                                                                        ] 
                                                                    }}`;
                await axios.get(contenutiURL).then((response) => {
                    const results = response.data;
                    setContenutiResult(results);
                });
                
                //ricerca su vite in movimento (le schede degli intellettuali)
                const lingua = i18n.language.substring(0,2) === "it" ? 1 : 2;
                let intellettualiFilter="";
                let mobilitaFilter="";

                //prima di tutto si crea un filtro in base ai risultati sui dati degli intellettuali (indipendentemente dalla lingua)
                const intellettualiURL = baseURL+`/intellettuali?filter={"where": {"or" : [{"nome" :{"regexp": "/${searchString}/i"}},
                                                                                           {"cognome" :{"regexp": "/${searchString}/i"}},
                                                                                           {"dateEsistenza" :{"regexp": "/${searchString}/i"}},
                                                                                           {"altreIntestazioni" :{"regexp": "/${searchString}/i"}},
                                                                                           {"alias" :{"regexp": "/${searchString}/i"}}
                                                                                          ]}}`;
                await axios.get(intellettualiURL).then((response) => {
                    const results = response.data;
                    let intellettualiIds = results.map(result => result.id);
                    intellettualiFilter = `{"idIntellettuale" : {"inq":[${intellettualiIds}]}}`
                });

                //poi si crea un filtro cercando fra tutte le mobilità
                const mobilitaURL = baseURL+`/mobilita?filter={"where": {"or" : [{"titoloBreve" :{"regexp": "/${searchString}/i"}},
                                                                                {"descrizione" :{"regexp": "/${searchString}/i"}},
                                                                                {"luogoPrincipale" :{"regexp": "/${searchString}/i"}},
                                                                                {"luoghiEntiStudio" :{"regexp": "/${searchString}/i"}},
                                                                                {"posizioni" :{"regexp": "/${searchString}/i"}},
                                                                                {"areaprofessionale" :{"regexp": "/${searchString}/i"}}
                                                                                ]}}`;  
                await axios.get(mobilitaURL).then((response) => {
                    const results = response.data;
                    let schedeIds = results.map(result => result.idScheda);
                    mobilitaFilter = `{"id" : {"inq":[${schedeIds}]}}`;
                });

                //infine si tirano giù le schede che rispettano i filtri e che hanno la stringa fra il pdf o gli approfondimenti
                const schedeURL = baseURL+`/schede?filter={"where": {"and" : [
                                                                    {"or" : [${intellettualiFilter},
                                                                            ${mobilitaFilter},
                                                                            {"datazioneNascita" :{"regexp": "/${searchString}/i"}},
                                                                            {"datazioneMorte" :{"regexp": "/${searchString}/i"}},
                                                                            {"paesenascita" :{"regexp": "/${searchString}/i"}},
                                                                            {"areadisciplinare" :{"regexp": "/${searchString}/i"}},
                                                                            {"testopdf" :{"regexp": "/${searchString}/i"}},
                                                                            {"familiariRelazioni" :{"regexp": "/${searchString}/i"}},
                                                                            {"entiSoccorsoDescrizione" :{"regexp": "/${searchString}/i"}},
                                                                            {"referenze" :{"regexp": "/${searchString}/i"}},
                                                                            {"relazioniAiuto" :{"regexp": "/${searchString}/i"}}
                                                                            ]},
                                                                    {"lingua" : ${lingua}}, 
                                                                    {"pubblico" : "true"}] 
                                                                }, "include": [
                {
                    "relation": "intellettualiRelation"
                }
                ]}`
                await axios.get(schedeURL).then((response) => {
                    const results = response.data;
                    results.sort(compareResultsSchede);
                    setViteInMovimentoResult(results);     
                });

                /* //Adesso si cerca solo nel campo testo pdf per trovare le schede che hanno la stringa nel pdf e si riporta il link al file direttamente
                let pdfFilter="";
                const pdfUrl = baseURL+`/schede?filter={"where": {"and" : [
                                                                    {"testopdf" :{"regexp": "/${searchString}/i"}},
                                                                    {"lingua" : ${lingua}}, 
                                                                    {"pubblico" : "true"}] 
                                                                },"include": [
                                                                    {
                                                                        "relation": "intellettualiRelation"
                                                                    }
                                                                    ]}`
                let intellettuali = [];
                await axios.get(pdfUrl).then((response) => {
                    intellettuali = response.data;
                    let fileschedeIds = intellettuali.map(result => result.fileschedaattivo);
                    pdfFilter = `{"id" : {"inq":[${fileschedeIds}]}}`   
                }); 

                const fileSchedeUrl = baseURL+`/fileSchede?filter={"where": ${pdfFilter}}`
                
                await axios.get(fileSchedeUrl).then((response) => {
                    let results = response.data;
                    if (results.lenght!==0) {
                        results = mergeArrayIntellettualiAndPdf(intellettuali, results);
                        results.sort(compareResultsSchede);
                    }
                    setPdfResult(results);
                }); */
                
            }
        }
        fetchData();
    },[searchString, t]);

    useEffect(() => {
        formRef.current.reset();
        setActualString("");
        setSearchString("");
    },[t]);

    useEffect(() => {
        const lang = i18n.language.substring(0,2) === "it" ? "it" : "en";
        setLang(lang);
    },[]);

    const handleSubmit = (event) =>{
        setSearchString(actualString);
        event.preventDefault();
    }


    
    if(searchString==="") return (
        <form onSubmit={handleSubmit} ref={formRef}>
            <div className='int__ricerca-container'>
                <div className='int__ricerca-form-container'> 
                    <div className='int__ricerca-form'>
                        <input value={actualString}  onChange={(e) => setActualString(e.target.value)} type="text" />                                         
                        <div className='int__ricerca-form-submit'>
                            <input type="submit" value={t("Cerca")} className="int__ricerca-button" />
                        </div>
                    </div>                
                </div>
            </div>
        </form>
    )

    if(contenutiResult.length===0 && viteInMovimentoResult.length===0 /* && pdfResult.length===0 */) return (
        <>
        <form onSubmit={handleSubmit} ref={formRef}>
            <div className='int__ricerca-container'>
                <div className='int__ricerca-form-container'>
                    <div className='int__ricerca-form'>
                        <input value={actualString}  onChange={(e) => setActualString(e.target.value)} type="text" />                                         
                        <div className='int__ricerca-form-submit'>
                            <input type="submit" value={t("Cerca")} className="int__ricerca-button" />
                        </div>
                    </div>                
                </div>
            </div>
        </form>
        <div className='int__ricerca-numerorisultati'>
            {t("Nessun risultato")}
        </div>
        </>
    )

    return (
    <>
        <form onSubmit={handleSubmit} ref={formRef}>
            <div className='int__ricerca-container'>
                <div className='int__ricerca-form-container'> 
                    <div className='int__ricerca-form'>
                        <input value={actualString}  onChange={(e) => setActualString(e.target.value)} type="text" />                                         
                        <div className='int__ricerca-form-submit'>
                            <input type="submit" value={t("Cerca")} className="int__ricerca-button" />
                        </div>
                    </div>                
                </div>
            </div>
        </form>
        <div className='int__ricerca-elenco'>
            <div>
                <div className='int__ricercatestuale-numerorisultati'>
                    {t("Risultati")}: {contenutiResult.length+viteInMovimentoResult.length/* +pdfResult.length */}
                </div>
                {viteInMovimentoResult.length!==0 
                    && viteInMovimentoResult.map((vita) => 
                        <Link className='int__ricerca-testuale-item-elenco-link'
                        to={`/${lang}/${vita.intellettualiRelation.cognome}/${vita.intellettualiRelation.nome}/${vita.idIntellettuale}`}
                        target="_blank"
                        state={{searchString:searchString}}
                        key={"intellettuale"+vita.id}>
                        {vita.intellettualiRelation.cognome}, {vita.intellettualiRelation.nome} {vita.intellettualiRelation.dateEsistenza}
                            <div className='int__ricerca-item-contenuto'>
                                {t("Vite in movimento")}
                            </div>
                        </Link>
                    ) 
                }
                {/* {pdfResult.length!==0 
                    && pdfResult.map((pdf) => 
                        <a className='int__ricerca-testuale-item-elenco-link' href={baseURL+"/files/"+pdf.nomefile.slice(0,-3)+"pdf"} key={"file"+pdf.id} target="_blank">
                        {pdf.intellettualiRelation.cognome}, {pdf.intellettualiRelation.nome} {pdf.intellettualiRelation.dateEsistenza}
                            <div className='int__ricerca-item-contenuto'>
                                {t("Vite in movimento")}
                                <div className='int__ricerca-item-img'>
                                    <img src={PdfBadge}/>
                                </div>
                            </div>
                        </a>
                    ) 
                } */}
                {contenutiResult.length!==0 
                    && contenutiResult.map((contenuto) => {
                        if (lang === "it") {
                            const route = routes.find((route) => route.titolo_it===contenuto.titolo);
                            if (route) {
                                return (
                                    <Link className='int__ricerca-testuale-item-elenco-link'
                                    to={`/${lang}/${route.path_it}`}
                                    target="_blank"
                                    key={"contenuto"+contenuto.id}>
                                    {contenuto.titolo}
                                        <div className='int__ricerca-item-contenuto'>
                                            {t("Contenuti")}
                                        </div>
                                    </Link>
                                )
                            }
                            return null;
                        } else {
                            const route = routes.find((route) => route.titolo_en===contenuto.titolo);
                            if (route) {
                                return (
                                    <Link className='int__ricerca-testuale-item-elenco-link'
                                        to={`/${lang}/${route.path_en}`}
                                        target="_blank"
                                        key={"contenuto"+contenuto.id}>
                                        {contenuto.titolo}
                                            <div className='int__ricerca-item-contenuto'>
                                                {t("Contenuti")}
                                            </div>
                                    </Link>
                                )
                            }
                            return null;
                        }
                    }
                    ) 
                }
                <Outlet/>
            </div>
        </div>
    </>
  )
}

export default RicercaTestuale
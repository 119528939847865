import React, { useState, useEffect } from 'react';
import ReactFlow, {
  ReactFlowProvider,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  useReactFlow
} from 'reactflow';
import 'reactflow/dist/style.css';

import { useTranslation } from "react-i18next";

import CircleNode from './custom-nodes/CircleNode';
import RedCircleNode from './custom-nodes/RedCircleNode';
import GreyCircleNode from './custom-nodes/GreyCircleNode';
import TriangleNode from './custom-nodes/TriangleNode';
import RedTriangleNode from './custom-nodes/RedTriangleNode';
import GreyTriangleNode from './custom-nodes/GreyTriangleNode';
import SquareNode from './custom-nodes/SquareNode';
import RedSquareNode from './custom-nodes/RedSquareNode';
import GreySquareNode from './custom-nodes/GreySquareNode';
import BigCircleNode from './custom-nodes/BigCircleNode';
import BigRedCircleNode from './custom-nodes/BigRedCircleNode';
import BigTriangleNode from './custom-nodes/BigTriangleNode';
import BigRedTriangleNode from './custom-nodes/BigRedTriangleNode';
import BigSquareNode from './custom-nodes/BigSquareNode';
import BigRedSquareNode from './custom-nodes/BigRedSquareNode';
import DotNode from './custom-nodes/DotNode';
import BigGreyCircleNode from './custom-nodes/BigGreyCircleNode';
import BigGreySquareNode from './custom-nodes/BigGreySquareNode';
import BigGreyTriangleNode from './custom-nodes/BigGreyTriangleNode';
import axios from 'axios';
import quadrato from'../../assets/quadrato2.jpg';
import cerchio from'../../assets/cerchio2.jpg';
import triangolo from'../../assets/triangolo2.jpg';
import cerchiogrigio from'../../assets/grigio.jpg';
import cerchiogrigiochiaro from'../../assets/grigiochiaro.jpg';
import cerchiorosso from'../../assets/rosso.png';

import baseURL from '../../config';

import './familytree.css';

const nodeTypes = {
  redcircle: RedCircleNode,
  circle: CircleNode,
  redtriangle: RedTriangleNode,
  triangle: TriangleNode,
  redsquare: RedSquareNode,
  square: SquareNode,
  dot: DotNode,
  greycircle: GreyCircleNode,
  greytriangle: GreyTriangleNode,
  greysquare: GreySquareNode,
  bigredcircle: BigRedCircleNode,
  bigcircle: BigCircleNode,
  bigredtriangle: BigRedTriangleNode,
  bigtriangle: BigTriangleNode,
  bigredsquare: BigRedSquareNode,
  bigsquare: BigSquareNode,
  biggreycircle: BigGreyCircleNode,
  biggreysquare: BigGreySquareNode,
  biggreytriangle: BigGreyTriangleNode
};

const OverviewFlow = (props) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [isSelectable, setIsSelectable] = useState(false);
  const [isDraggable, setIsDraggable] = useState(false);
  const [isConnectable, setIsConnectable] = useState(false);
  const [zoomOnScroll, setZoomOnScroll] = useState(true);
  const [panOnScroll, setPanOnScroll] = useState(false);
  const [panOnScrollMode, setPanOnScrollMode] = useState('free');
  const [zoomOnDoubleClick, setZoomOnDoubleClick] = useState(false);
  const [panOnDrag, setpanOnDrag] = useState(true);
  const [captureZoomClick, setCaptureZoomClick] = useState(false);
  const [captureZoomScroll, setCaptureZoomScroll] = useState(false);
  const [captureElementClick, setCaptureElementClick] = useState(false);
  const [thisIntellettuale, setThisIntellettuale] =  useState(props.intellettuale);
  const { setViewport } = useReactFlow();

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchData = async () =>{
      await axios.get(`${baseURL}/trees?filter={"where":{"idintellettuale": ${thisIntellettuale.id}}}`).then (resp => {
        if (resp.data.length!==0) {
          const { x = 0, y = 0, zoom = 1 } = resp.data[0].albero.viewport;
          setNodes(resp.data[0].albero.nodes || []);
          setEdges(resp.data[0].albero.edges || []);
          setViewport({ x, y, zoom });
        }
      });
    }
    fetchData();
  }, []);
  
  return (
    <div style={{ height: 300, width: "100%" }}>
        <div className="int__legenda_container">
          <div className="int__legenda_symbols">
            <div className="int__legenda_item"><img src={cerchio}/> &nbsp;&nbsp; {t("Femmina")}</div>
            <div className="int__legenda_item"><img src={triangolo}/> &nbsp;&nbsp; {t("Maschio")}</div>
            <div className="int__legenda_item"><img src={quadrato}/> &nbsp;&nbsp; {t("Non identificato")}</div>
          </div>
          <div className="int__legenda_colors">
            <div className="int__legenda_item"><img src={cerchiorosso}/> &nbsp;&nbsp; {t("Non in Italia")}</div>
            <div className="int__legenda_item"><img src={cerchiogrigio}/> &nbsp;&nbsp; {t("Rientrato/a non in attività lavorativa")}</div>
            <div className="int__legenda_item"><img src={cerchiogrigiochiaro}/> &nbsp;&nbsp; {t("Rientrato/a in Italia")}</div>
          </div>
        </div>
        <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        elementsSelectable={isSelectable}
        nodesConnectable={isConnectable}
        nodesDraggable={isDraggable}
        zoomOnScroll={zoomOnScroll}
        panOnScroll={panOnScroll}
        panOnScrollMode={panOnScrollMode}
        zoomOnDoubleClick={zoomOnDoubleClick}
        onNodeClick={!captureElementClick && undefined}
        panOnDrag={panOnDrag}
        onPaneClick={!captureZoomClick && undefined}
        onPaneScroll={!captureZoomScroll && undefined}
        onPaneContextMenu={!captureZoomClick && undefined}
        fitView
        maxZoom={1.5}
        attributionPosition="bottom-right"
      >
          <Controls position='right' showInteractive={false} />
          <Background color="#fff" variant="lines"/>
            
        </ReactFlow>
    </div>
  );
};


function FamilyTree(props) {
  return (
    <ReactFlowProvider>
      <OverviewFlow {...{intellettuale:props.intellettuale}}/>
    </ReactFlowProvider>
  );
}

export default FamilyTree;

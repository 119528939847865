import React, {useState, useEffect} from "react";
import './footer.css';
import Loghi from '../../assets/partners2.png';
import LogoFup from '../../assets/fuplogo.png';
import Modal from '../modal/Modal';
import axios from "axios";
import baseURL from '../../config';
import { useTranslation } from "react-i18next";

const Footer = (props) => {
    const [openModal, setOpenModal] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [buttonClickedName, setButtonClickedName] = useState("Contatti");
    const [contenutoId, setContenutoId] = useState(35);
    const { t, i18n } = useTranslation();
    const contenutiId = {
        it: {
            "Contatti": 37,
            "Links e collaborazioni":4,
            "Credits":35
            },
        en: {
            "Contatti": 31,
            "Links e collaborazioni":15,
            "Credits":8
        }
    };

    const handleClick = value => () => {
        setButtonClickedName(value);
        setOpenModal(true);
    }

    useEffect(() => {
        let lang = i18n.language.substring(0,2);
        if (lang !== "it") {
            lang = "en"
        } 
        axios.get(baseURL+"/contenuti/"+contenutiId[lang][buttonClickedName]).then((response) => {
            setModalContent(response.data);
        });
    },[t, buttonClickedName]);
    
    return(
        <>
        <div className="int__footer_container">
            {/* <div className="int__footer_buttons-container">
                <button className="int__footer_button" onClick={handleClick("Contatti")}>
                    {t("Contatti")}
                </button>
                <button className="int__footer_button" onClick={handleClick("Links e collaborazioni")}>
                    {t("Links e collaborazioni")}
                </button>
                <button className="int__footer_button" onClick={handleClick("Credits")}>
                    {t("Credits")}
                </button>    
            </div> */}
            {props.home 
            ? <>
                <div className="int__footer_loghi">
                    <img src={Loghi}/> 
                </div>
                <div className="int__footer_copyright_prima-pagina">
                    <p>© 2023 - Patrizia Guarnieri - <a href="mailto:patrizia.guarnieri@unifi.it">patrizia.guarnieri@unifi.it</a> | Published by Firenze University Press | <a href="https://www.fupress.com/it/contenuti/privacy-and-cookies-policy/8341" target={"_blank"}>Privacy</a></p>
                </div>
              </>
            :
            <div className="int__footer-logofup-container">
                <div className="int__footer_logofup">
                    <img src={LogoFup}/> 
                </div>
                <div className="int__footer_copyright">
                <p>© 2023 - Patrizia Guarnieri - <a href="mailto:patrizia.guarnieri@unifi.it">patrizia.guarnieri@unifi.it</a> | Published by Firenze University Press | <a href="https://www.fupress.com/it/contenuti/privacy-and-cookies-policy/8341" target={"_blank"}>Privacy</a> | </p>
                </div>
            </div>
             
            }         
        </div>
        {openModal && <Modal closeModal={setOpenModal} modalContent={modalContent}/>} 
        </>
    )
}

export default Footer
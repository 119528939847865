import React, { memo } from 'react';
import { Handle } from 'reactflow';
import "./styles.css";

export default memo(({ data }) => {
  return (
    <div className="int__big-square-node"
    style={{
      backgroundColor: "#E5E5E5",
      padding: "14px"
    }}>
      <Handle
        type="target"
        position="top"
        id="input"
        style={{ width:0, height: 0, top: -40, border:"none", backgroundColor: "#E5E5E5" }}
      />
      <div
      style={{
        marginTop: "-10px",
        marginLeft: "-100px"
      }}>
        <input className="nodrag" type="text" defaultValue={data.label} disabled style={{textAlign: "center", width:200, fontSize:15, fontWeight:700}}/>
      </div>
      <Handle
        type="target"
        position="left"
        id="input_left"
        style={{  width:0, height: 0, border:"none", backgroundColor: "transparent", top: 15, left:-80 }}
      />
      <Handle
        type="source"
        position="right"
        id="output_right"
        style={{  width:0, height: 0, border:"none", backgroundColor: "transparent", top: 15, right:-80 }}
      />
      <Handle
        type="source"
        position="bottom"
        id="output"
        style={{ width:0, height: 0, bottom: -20, border:"none", backgroundColor: "#E5E5E5" }}
      />
    </div>
  );
});

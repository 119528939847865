import React, {useEffect, useState, useRef } from 'react';
import './ricercavanzata.css'
import { useTranslation } from "react-i18next";
import axios from 'axios';
import baseURL from '../../config';
import {
    Outlet,
    Link
  } from "react-router-dom";

const  RicercaAvanzata = (props) => {
    const [lang, setLang] = useState(null);
    const { t, i18n } = useTranslation();
    
    const sessoOptions = [{label:"", value:1}, {label:"M", value:2}, {label:"F", value:3}]
    const disciplinaItOptions = [
            {id:"", name:""},
            {id:"Agraria", name:"Agraria"},
            {id:"Antichistica", name:"Antichistica"},
            {id:"Antropologia", name:"Antropologia"},
            {id:"Archeologia", name:"Archeologia"},
            {id:"Architettura", name:"Architettura"},
            {id:"Arte-musica-spettacolo", name:"Arte-musica-spettacolo"},
            {id:"Astronomia", name:"Astronomia"},
            {id:"Biologia", name:"Biologia"},
            {id:"Chimica", name:"Chimica"},
            {id:"Economia", name:"Economia"},
            {id:"Lingue", name:"Lingue"},
            {id:"Pedagogia", name:"Pedagogia"}, 
            {id:"Teologia", name:"Teologia"},
            {id:"Farmacologia", name:"Farmacologia"},
            {id:"Giurisprudenza", name:"Giurisprudenza"}, 
            {id:"Filologia", name:"Filologia"}, 
            {id:"Filosofia", name:"Filosofia"}, 
            {id:"Fisica", name:"Fisica"}, 
            {id:"Geografia", name:"Geografia"}, 
            {id:"Ingegneria", name:"Ingegneria"}, 
            {id:"Letteratura", name:"Letteratura"}, 
            {id:"Linguistica", name:"Linguistica"}, 
            {id:"Matematica", name:"Matematica"}, 
            {id:"Medicina", name:"Medicina"}, 
            {id:"Psicologia", name:"Psicologia"}, 
            {id:"Scienze politiche", name:"Scienze politiche"}, 
            {id:"Sociologia", name:"Sociologia"}, 
            {id:"Scienze della terra", name:"Scienze della terra"}, 
            {id:"Statistica", name:"Statistica"}, 
            {id:"Storia", name:"Storia"}, 
            {id:"Storia dell’arte", name:"Storia dell’arte"}, 
            {id:"Veterinaria", name:"Veterinaria"}, 
            {id:"Altro", name:"Altro"}
        ]
    
    const disciplinaEnOptions = [
            {id:"", name:""},
            {id:"Agronomy", name:"Agronomy"},
            {id:"Antiquities", name:"Antiquities"},
            {id:"Anthropology", name:"Anthropology"},
            {id:"Archaeology", name:"Archaeology"},
            {id:"Architecture", name:"Architecture"},
            {id:"Art-Music-Theatre", name:"Art-Music-Theatre"},
            {id:"Astronomy", name:"Astronomy"},
            {id:"Biology", name:"Biology"},
            {id:"Chemistry", name:"Chemistry"},
            {id:"Economics", name:"Economics"},
            {id:"Languages", name:"Languages"},
            {id:"Education", name:"Education"}, 
            {id:"Theology", name:"Theology"},
            {id:"Pharmacology", name:"Pharmacology"},
            {id:"Law", name:"Law"}, 
            {id:"Philology", name:"Philology"}, 
            {id:"Philosophy", name:"Philosophy"}, 
            {id:"Physics", name:"Physics"}, 
            {id:"Geography", name:"Geography"}, 
            {id:"Engineering", name:"Engineering"}, 
            {id:"Literature", name:"Literature"}, 
            {id:"Linguistics", name:"Linguistics"}, 
            {id:"Mathematics", name:"Mathematics"}, 
            {id:"Medicine", name:"Medicine"}, 
            {id:"Psychology", name:"Psychology"}, 
            {id:"Political Science", name:"Political Science"}, 
            {id:"Sociology", name:"Sociology"}, 
            {id:"Earth Sciences", name:"Earth Sciences"}, 
            {id:"Statistics", name:"Statistics"}, 
            {id:"History", name:"History"}, 
            {id:"Art History", name:"Art History"}, 
            {id:"Veterinary medicine", name:"Veterinary medicine"}, 
            {id:"Others", name:"Others"}    
        ]

    const profeessioneItOptions = [
            {id:"", name:""},
            {id:"Artista-Musicista", name:"Artista/Musicista"},
            {id:"Avvocato-a", name:"Avvocato/a"},
            {id:"Consulente", name:"Consulente"},
            {id:"Dentista", name:"Dentista"},
            {id:"Dirigente-Imprenditore", name:"Dirigente/Imprenditore"},
            {id:"Funzionario-a", name:"Funzionario/a"},
            {id:"Giornalista", name:"Giornalista"},
            {id:"Impiegato-a", name:"Impiegato/a"},
            {id:"Insegnante", name:"Insegnante"},
            {id:"Lavoro non intellettuale", name:"Lavoro non intellettuale"},
            {id:"Medico", name:"Medico"},
            {id:"Rabbino", name:"Rabbino"},
            {id:"Tecnico-a", name:"Tecnico/a"},
            {id:"Psicologo-a", name:"Psicologo/a"},
            {id:"Tirocinante", name:"Tirocinante"},
            {id:"Traduttore-traduttrice", name:"Traduttore/traduttrice"},
            {id:"Docente universitario-a volontario-a", name:"Docente universitario/a volontario/a"},
            {id:"Libero-a docente", name:"Libero/a docente"},
            {id:"Lettore-lettrice", name:"Lettore/lettrice"},
            {id:"Ricercatore-ricercatrice", name:"Ricercatore/ricercatrice"},
            {id:"Docente universitario-a non di ruolo", name:"Docente universitario/a non di ruolo"},
            {id:"Assistente universitario-a", name:"Assistente universitario/a"},
            {id:"Geologo-a", name:"Geologo/a"},
            {id:"Ingegnere", name:"Ingegnere"},
            {id:"Politico-a", name:"Politico/a"},
            {id:"Architetto-a", name:"Architetto/a"},
            {id:"Archivistica-bibliotecario-a", name:"Archivista/bibliotecario/a"},
            {id:"Diplomatico-a", name:"Diplomatico/a"},
            {id:"Altro", name:"Altro"},
        ]
        
        const profeessioneEnOptions = [
            {id:"", name:""},
            {id:"Artist-Musician", name:"Artist/Musician"}, 
            {id:"Lawyer", name:"Lawyer"},
            {id:"Consultant", name:"Consultant"},
            {id:"Dentist", name:"Dentist"},
            {id:"Manager-Entrepreneur", name:"Manager / Entrepreneur"},
            {id:"Official", name:"Official"},
            {id:"Journalist", name:"Journalist"},
            {id:"Office worker", name:"Office worker"},
            {id:"Teacher", name:"Teacher"},
            {id:"Non-intellectual job", name:"Non-intellectual job"},
            {id:"Doctor", name:"Doctor"},
            {id:"Rabbi", name:"Rabbi"},
            {id:"Technician", name:"Technician"},
            {id:"Psychologist", name:"Psychologist"},
            {id:"Intern", name:"Intern"},
            {id:"Translator", name:"Translator"},
            {id:"Unpaid university lecturer", name:"Unpaid university lecturer"},
            {id:"Libero docente", name:"Libero docente"},
            {id:"University language teacher", name:"University language teacher"},
            {id:"Researcher", name:"Researcher"},
            {id:"Untenured professor", name:"Untenured professor"},
            {id:"Junior lecturer", name:"Junior lecturer"},
            {id:"Tenured professor", name:"Tenured professor"},
            {id:"Geologist", name:"Geologist"},
            {id:"Engineer", name:"Engineer"},
            {id:"Politician", name:"Politician"},
            {id:"Architect", name:"Architect"},
            {id:"Archivist-librarian", name:"Archivist/librarian"},
            {id:"Diplomat", name:"Diplomat"},
            {id:"Others", name:"Others"},
        ]
    
    const formRef = useRef();
    
    const [disciplinaOptions, setDisciplinaOptions] = useState([]);
    const [professioneOptions, setProfessioneOptions] = useState([]);    
    const [paeseNascitaOptions, setPaeseNascitaOptions] = useState([]);

    const [sexparams, setSexParams] = useState(null);
    const [disciplinaParams, setDisciplinaParams] = useState(null);
    const [professioneParams, setProfessioneParams] = useState(null);
    const [paeseParams, setPaeseParams] = useState(null);

    const [campiRicercaAvanzata, setCampiRicercaAvanzata] = useState({});
    const [searchResult, setSearchResult] = useState([]);

    const compareResultsSchede = (a,b) => {
        if (a.intellettualiRelation.cognome < b.intellettualiRelation.cognome) return -1;
        if (a.intellettualiRelation.cognome > b.intellettualiRelation.cognome) return 1;
        return 0;
    }

    const compareOptions = (a,b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    }

    useEffect(() => {
        const lingua = i18n.language.substring(0,2) === "it" ? 1 : 2;
        setLang(i18n.language.substring(0,2))
        const schedeURL = baseURL+`/schede?filter={"where": {"and" : [{"pubblico" : "true"}, {"lingua" : ${lingua}}] },"fields": {"paesenascita" : true}}`
        axios.get(schedeURL).then((response) => {
            const results = response.data;
            let paesi = [{value:""}];
            results.forEach(element => {
                element.paesenascita && paesi.push({value:element.paesenascita})
            });
            setPaeseNascitaOptions(paesi);
            if (lingua === 1) {
                disciplinaItOptions.sort(compareOptions);
                profeessioneItOptions.sort(compareOptions);
                setDisciplinaOptions(disciplinaItOptions);
                setProfessioneOptions(profeessioneItOptions);
            } else {
                disciplinaEnOptions.sort(compareOptions);
                profeessioneEnOptions.sort(compareOptions);
                setDisciplinaOptions(disciplinaEnOptions);
                setProfessioneOptions(profeessioneEnOptions);
            }
            formRef.current.reset();
            setSexParams(null);
            setDisciplinaParams(null);
            setProfessioneParams(null);
            setPaeseParams(null);
            setCampiRicercaAvanzata({});
        });
    },[t]);

    useEffect(() => {
        const fetchData = async () => {
            if (Object.keys(campiRicercaAvanzata).length !== 0) {
                const lingua = i18n.language.substring(0,2) === "it" ? 1 : 2;
                const paeseFilter = "paese" in campiRicercaAvanzata ? `{"paesenascita" : "${campiRicercaAvanzata.paese}"},` : ""
                const disciplinaFilter = "disciplina" in campiRicercaAvanzata ? `{"areadisciplinare" : {"regexp": "/${campiRicercaAvanzata.disciplina}/i"}},` : "";
                
                let sessoFilter="";
                let professioneFilter="";

                if ("sesso" in campiRicercaAvanzata) {
                    const sessoURL = baseURL+`/intellettuali?filter={"where": {"genere" : ${campiRicercaAvanzata.sesso}}}`;
                    await axios.get(sessoURL).then((response) => {
                        const results = response.data;
                        let intellettualiIds = results.map(result => result.id);
                        sessoFilter = `{"idIntellettuale" : {"inq":[${intellettualiIds}]}},`
                    });
                }

                
                if ("professione" in campiRicercaAvanzata) {
                    const professioneURL = baseURL+`/mobilita?filter={"where": {"areaProfessionale" : {"regexp": "/${campiRicercaAvanzata.professione}/i"}}}`;  
                    await axios.get(professioneURL).then((response) => {
                        const results = response.data;
                        let schedeIds = results.map(result => result.idScheda);
                        professioneFilter = `{"id" : {"inq":[${schedeIds}]}},`
                    });
                }
                
                const schedeURL = baseURL+`/schede?filter={"where": {"and" : [${sessoFilter} ${professioneFilter}${paeseFilter} ${disciplinaFilter}{"lingua" : ${lingua}}, 
                                                                            {"pubblico" : "true"}] }, 
                                                                            "include": [
                {
                    "relation": "intellettualiRelation"
                }
                ]}`;
                
                await axios.get(schedeURL).then((response) => {
                    const results = response.data;
                    results.sort(compareResultsSchede);
                    setSearchResult(results);     
                });
            }
        }
        fetchData();
        
    },[campiRicercaAvanzata]);
    

    const handleSubmit = (event) =>{

        let searchParams = {};
        if (sexparams!==null && sexparams!=="" && sexparams!=1) {
            searchParams.sesso = sexparams;
        }
        if (disciplinaParams!==null && disciplinaParams!=="") {
            searchParams.disciplina = disciplinaParams;
        }
        if (professioneParams!==null && professioneParams!=="") {
            searchParams.professione = professioneParams;
        }
        if (paeseParams!==null && paeseParams!=="") {
            searchParams.paese = paeseParams;
        }

        if (searchParams.length !== 0) {
            setCampiRicercaAvanzata(searchParams)
        }else {
            setCampiRicercaAvanzata({})
        }

        event.preventDefault();
    }

    if (Object.keys(campiRicercaAvanzata).length===0 ) return (
        <form onSubmit={handleSubmit} ref={formRef}>
            <div className='int__ricercaavanzata-container' key={"ricercaavanzata"}>
                <div className='int__ricercaavanzata-labels' key={"sesso"}>
                    <p>{t("Genere")}</p>
                    <select defaultValue="" onChange={(e) => setSexParams(e.target.value)} key={"sessoselect"}>
                        {sessoOptions.map((sex) => (
                        <option value={sex.value} key={"sex"+sex.value}>{sex.label}</option>
                        ))}
                </select>
                </div>
                <div className='int__ricercaavanzata-labels' key={"disciplina"}>
                    <p>{t("Aree disciplinari")}</p>
                    <select defaultValue="" onChange={(e) => setDisciplinaParams(e.target.value)} key={"disciplinaselect"}>
                        {disciplinaOptions.map((disciplina) => (
                        <option value={disciplina.id}key={"disciplina"+disciplina.name}>{disciplina.name}</option>
                        ))}
                </select>
                </div>
                <div className='int__ricercaavanzata-labels' key={"professione"}>
                    <p>{t("Professione")}</p>
                    <select defaultValue="" onChange={(e) => setProfessioneParams(e.target.value)} key={"professioneselect"}>
                        {professioneOptions.map((professione) => (
                        <option value={professione.id} key={"professione"+professione.name}>{professione.name}</option>
                        ))}
                </select>
                </div>
                {/* <div className='int__ricercaavanzata-labels' key={"paese"}>
                    <p>{t("Paese di nascita")}</p>
                    <select defaultValue="" onChange={(e) => setPaeseParams(e.target.value)} key={"paeseselect"}>
                        {paeseNascitaOptions.map((paese) => (
                        <option value={paese.value}>{paese.value}</option>
                        ))}
                </select>
                </div> */}
                <input type="submit" value={t("Cerca")} className="int__ricercaavanzata-button" />
            </div>
        </form>
    );

    if(searchResult.length===0) return (
        <>
        <form onSubmit={handleSubmit} ref={formRef}>
            <div className='int__ricercaavanzata-container' key={"ricercaavanzata"}>
                <div className='int__ricercaavanzata-labels' key={"sesso"}>
                    <p>{t("Genere")}</p>
                    <select defaultValue="" onChange={(e) => setSexParams(e.target.value)} key={"sessoselect"}>
                        {sessoOptions.map((sex) => (
                        <option value={sex.value} key={"sex"+sex.value}>{sex.label}</option>
                        ))}
                </select>
                </div>
                <div className='int__ricercaavanzata-labels' key={"disciplina"}>
                    <p>{t("Aree disciplinari")}</p>
                    <select defaultValue="" onChange={(e) => setDisciplinaParams(e.target.value)} key={"disciplinaselect"}>
                        {disciplinaOptions.map((disciplina) => (
                        <option value={disciplina.id}key={"disciplina"+disciplina.name}>{disciplina.name}</option>
                        ))}
                </select>
                </div>
                <div className='int__ricercaavanzata-labels' key={"professione"}>
                    <p>{t("Professione")}</p>
                    <select defaultValue="" onChange={(e) => setProfessioneParams(e.target.value)} key={"professioneselect"}>
                        {professioneOptions.map((professione) => (
                        <option value={professione.id}key={"professione"+professione.name}>{professione.name}</option>
                        ))}
                </select>
                </div>
                {/* <div className='int__ricercaavanzata-labels' key={"paese"}>
                    <p>{t("Paese di nascita")}</p>
                    <select defaultValue="" onChange={(e) => setPaeseParams(e.target.value)} key={"paeseselect"}>
                        {paeseNascitaOptions.map((paese) => (
                        <option value={paese.value}>{paese.value}</option>
                        ))}
                </select>
                </div> */}
                <input type="submit" value={t("Cerca")} className="int__ricercaavanzata-button" />
            </div>
        </form>
        <div className='int__ricercaavanzata-numerorisultati'>
            {t("Nessun risultato")}
        </div>
        </>
    )

    if (!professioneOptions) return null;

    return (
        <>
        <form onSubmit={handleSubmit} ref={formRef}>
            <div className='int__ricercaavanzata-container' key={"ricercaavanzata"}>
                <div className='int__ricercaavanzata-labels' key={"sesso"}>
                    <p>{t("Genere")}</p>
                    <select defaultValue="" onChange={(e) => setSexParams(e.target.value)} key={"sessoselect"}>
                        {sessoOptions.map((sex) => (
                        <option value={sex.value} key={"sex"+sex.value}>{sex.label}</option>
                        ))}
                </select>
                </div>
                <div className='int__ricercaavanzata-labels' key={"disciplina"}>
                    <p>{t("Aree disciplinari")}</p>
                    <select defaultValue="" onChange={(e) => setDisciplinaParams(e.target.value)} key={"disciplinaselect"}>
                        {disciplinaOptions.map((disciplina) => (
                        <option value={disciplina.id} key={"disciplina"+disciplina.name}>{disciplina.name}</option>
                        ))}
                </select>
                </div>
                <div className='int__ricercaavanzata-labels' key={"professione"}>
                    <p>{t("Professione")}</p>
                    <select defaultValue="" onChange={(e) => setProfessioneParams(e.target.value)} key={"professioneselect"}>
                        {professioneOptions.map((professione) => (
                        <option value={professione.id} key={"professione"+professione.name}>{professione.name}</option>
                        ))}
                </select>
                </div>
                {/* <div className='int__ricercaavanzata-labels' key={"paese"}>
                    <p>{t("Paese di nascita")}</p>
                    <select defaultValue="" onChange={(e) => setPaeseParams(e.target.value)} key={"paeseselect"}>
                        {paeseNascitaOptions.map((paese) => (
                        <option value={paese.value}>{paese.value}</option>
                        ))}
                </select>
                </div> */}
                <input type="submit" value={t("Cerca")} className="int__ricercaavanzata-button" />
            </div>
        </form>
        <div className='int__ricerca-elenco'>
            <div>
                <div className='int__ricercaavanzata-numerorisultati' key={"ricerca_avanzata"}>
                    {t("Risultati")}: {searchResult.length}
                </div>
                {searchResult.map((result) => 
                    <Link className='int__ricerca-testuale-item-elenco-link'
                    to={`/${lang}/${result.intellettualiRelation.cognome}/${result.intellettualiRelation.nome}/${result.idIntellettuale}`}
                    target="_blank"
                    key={result.id}>
                        {result.intellettualiRelation.cognome}, {result.intellettualiRelation.nome} {result.intellettualiRelation.dateEsistenza}
                        <div className='int__ricerca-item-contenuto' key={result.id}>
                            {t("Vite in movimento")}
                        </div>
                    </Link>
                )}
                <Outlet/>
            </div>
        </div>
        </>
    )
}

export default RicercaAvanzata
import React, { memo } from 'react';
import { Handle } from 'reactflow';
import "./styles.css";

export default memo(({ data }) => {
  return (
    <div className="int__big-grey-circle-node"
      style={{
        backgroundColor: "#4f4f4f",
        padding: "14px",
        borderRadius: "100px"
      }}
    >
      <Handle
        type="target"
        position="top"
        id="input"
        style={{ width:0, height: 0, top: -40, border:"none", backgroundColor: "transparent" }}
      />
      <div
      style={{
        marginTop: "-12px",
        marginLeft: "-100px"
      }}>
        <input className="nodrag" type="text" defaultValue={data.label} onChange={event => data.label=event.target.value} style={{textAlign: "center", width:200, fontSize:15, fontWeight:700}}/>
      </div>
      <Handle
        type="target"
        position="left"
        id="input_left"
        style={{   width:0, height: 0, border:"none", backgroundColor: "transparent", top: 15, left:-85 }}
      />
      <Handle
        type="source"
        position="right"
        id="output_right"
        style={{  width:0, height: 0, border:"none", backgroundColor: "transparent", top: 15, right:-85 }}
      />
      <Handle
        type="source"
        position="bottom"
        id="output"
        style={{ width:0, height: 0, bottom: -22, border:"none", backgroundColor: "transparent" }}
      />
    </div>
  );
});

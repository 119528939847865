import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter, Routes, Route, Navigate, Outlet, useParams, Link } from 'react-router-dom';
import Scheda from './scheda';
import { Navbar, Contenuti, Ricerca, Elenco} from './components';
import Footer from './containers/footer/Footer';
import './i18nextConf'
import { Trans, useTranslation } from "react-i18next";
import { ContentPasteSearchOutlined } from '@mui/icons-material';

import ReactGA from 'react-ga4';

function handelRightClick(event) {
  event.preventDefault();
}

//TRACKING ID GOOGLE ANALYTICS
const TRACKING_ID = "G-8S7RVHBBHG";
ReactGA.initialize(TRACKING_ID);

//QUESTO OBJECT SERVE SOTTO PER LE ROUTES ED IN RICERCA TESTUALE, NEL MENU E NELLA BARRA DI NAVIGAZIONE
const routes = [
  {path_it:"presentazione", path_en:"foreword", titolo_it:"Presentazione", titolo_en:"Foreword"},
  {path_it:"presentazione_seconda_ed", path_en:"foreword_second_ed", titolo_it: "Presentazione della 2. edizione", titolo_en: "Foreword of the 2. edition"},
  {path_it:"emigrazione_intellettuale", path_en:"intellectual_emigration", titolo_it: "L'emigrazione intellettuale", titolo_en: "Intellectual emigration"},
  {path_it:"intellettuali_in_fuga", path_en:"displaced_intellectuals", titolo_it:"Gli intellettuali in fuga. Chi erano?", titolo_en:"Displaced intellectuals - Who were they?"},
  {path_it:"nomi_e_cognomi", path_en:"first_names_surnames", titolo_it:"Nomi e cognomi", titolo_en:"First names and surnames"},
  {path_it:"volti_persone_storie", path_en:"faces_peoples_stories", titolo_it: "Volti, persone e storie", titolo_en: "Faces, people and stories"},
  {path_it:"viteinmovimento", path_en:"livesonthemove", titolo_it:"Vite in movimento", titolo_en:"Lives on the move"},
  {path_it:"reti_familiari", path_en:"family_networks", titolo_it: "Reti familiari", titolo_en: "Family networks"},
  {path_it:"work_in_progress", path_en:"work_in_progress", titolo_it:"Work in progress", titolo_en:"Work in progress"},
  {path_it:"ricerche_risorse_digitali", path_en:"research_digital_resources", titolo_it:"Ricerche e risorse digitali", titolo_en:"Research and digital resources"},
  {path_it:"avvertenze_ricerca", path_en:"notes_on_research", titolo_it: "Avvertenze per la ricerca", titolo_en: "Notes on research"},
  {path_it:"fotografie", path_en:"photos", titolo_it:"Fotografie: il nostro archivio", titolo_en:"Our photograph archive"},
  {path_it:"normative", path_en:"legislation", titolo_it:"Normative fasciste e normative riparatorie (1925-2000)", titolo_en:"Fascist legislation and reparations legislation (1925-2000)"},
  {path_it:"leggi_fascistissime", path_en:"leggi_fascistissime", titolo_it:"Leggi fascistissime e leggi razziste", titolo_en:"\"Leggi fascistissime\" and the racist laws"},
  {path_it:"accanimento_e_riparazione", path_en:"persecution_reparation", titolo_it:"Tra accanimento e riparazione", titolo_en:"From persecution to reparation"},
  {path_it:"anni_della_repubblica", path_en:"years_of_republic", titolo_it:"Gli anni della Repubblica", titolo_en:"The years of the Republic"},
  {path_it:"archivi_consultati", path_en:"consulted_archives", titolo_it:"Archivi consultati", titolo_en:"Consulted archives"},
  {path_it:"bibliografia", path_en:"bibliography", titolo_it:"Bibliografia", titolo_en:"Bibliography"},
  {path_it:"credits", path_en:"credits", titolo_it: "Credits", titolo_en: "Credits"},
  {path_it:"autrici_e_autori", path_en:"authors", titolo_it:"Autrici e autori degli articoli PDF", titolo_en:"Authors of the PDF articles"},
  {path_it:"traduttrici_e_traduttori", path_en:"translators", titolo_it:"Traduttrici e traduttori", titolo_en:"Translators"},
  {path_it:"ringraziamenti", path_en:"acknowledgements", titolo_it:"Ringraziamenti", titolo_en:"Acknowledgements"},
  {path_it:"cosa_ce_di_nuovo", path_en:"whats_new", titolo_it:"Cosa c'è di nuovo", titolo_en:"News and updates"},
  {path_it:"dicono_di_noi", path_en:"about_us", titolo_it:"Dicono di noi", titolo_en:"About us"},
  {path_it:"sostenitori", path_en:"partnerships", titolo_it: "Sostenitori e collaborazioni", titolo_en: "Links and partnerships"},
  {path_it:"licenze", path_en:"licenses", titolo_it: "Licenze d'uso", titolo_en: "Licenses"}
];

const NotFoundPage = () => {
  return (
    <div>
      <h1 style={{ color: "red", fontSize: 100 }}>404</h1>
      <h3>Page Not Found</h3>
      <p>
      </p>
    </div>
  );
};

const ContenutiContainer = () =>{
  const [isValidLang, setIsValidLang] = useState(false);
  let params = useParams();
  const { t, i18n } = useTranslation(); 

  useEffect(() => {
    const isvalid = params.lang=="it" || params.lang=="en" || params.lang==undefined
    setIsValidLang(isvalid)
    if (isvalid) {
      i18n.changeLanguage(params.lang)
    }
  },[]);
  
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page_title: window.location.pathname});
  },[window.location.pathname]);

  return(
    <> 
    {isValidLang ?
    <>
      <Navbar/>
      <Contenuti/>
      <Footer/>
    </>
    : null
    }
    </>
  )
}

const ElencoContainer = () =>{
  const [isValidLang, setIsValidLang] = useState(false);
  let params = useParams();
  const { t, i18n } = useTranslation(); 

  useEffect(() => {
    const isvalid = params.lang=="it" || params.lang=="en" || params.lang==undefined;
    setIsValidLang(isvalid);
    if (isvalid) {
      i18n.changeLanguage(params.lang)
    }
    
  },[]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page_title: window.location.pathname});
  },[window.location.pathname]);
  
  return(
    <> 
    {isValidLang ?
    <>
      <Navbar/>
      <Elenco/>
      <Footer/>
    </>
    : null
    }
    </>
  )
}

const RicercaContainer = () =>{
  const [isValidLang, setIsValidLang] = useState(false);
  let params = useParams();
  const { t, i18n } = useTranslation(); 

  useEffect(() => {
    const isvalid = params.lang=="it" || params.lang=="en" || params.lang==undefined;
    setIsValidLang(isvalid);
    if (isvalid) {
      i18n.changeLanguage(params.lang)
    }
  },[]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page_title: window.location.pathname});
  },[window.location.pathname]);
  
  return(
    <> 
    {isValidLang ?
    <>
      <Navbar/>
      <Ricerca/>
      <Footer/>
    </>
    : null
    }
    </>
  )
}

const MyRoutes = () => {
  const { t, i18n } = useTranslation();
  document.addEventListener('contextmenu', handelRightClick);
  const lang = i18n.language;

  return (
    <Routes>
      <Route path="/" element={<App/>} key={"homepath"}/> 
      <Route path={`/:lang`} element={<App/>} key={"homepath_lang"}/> 
      <Route exact path="/" key={"navbar"}>
        <Route path={":lang"} key={"lang"}>
          <Route path=":cognome" key={"intellettuali_cognome"}>
            <Route path=":nome" key={"intellettuali_nome"}>
              <Route path=":id" element={<><Navbar/><Scheda/><Footer/></>} key={"id_intellettuale"}/>
            </Route>
          </Route>
          {routes.map(route => (
          <Route path={route.path_it} element={<ContenutiContainer/>} key={route.path_it}/>
          ))
          }
          <Route path="elenco_intellettuali" element={<ElencoContainer/>} key={"intellettuali_elenco"}/>
          <Route path="guida_ricerca" element={<RicercaContainer/>} key={"ricerca"}/>
          {/* <Route path="video-playlist" element={<><VideoPlaylist/><Footer/></>} key={"videoplaylist"}/> */}
        </Route>
      </Route>
      <Route exact path="/" key={"navbar"}>
        <Route path={":lang"} key={"lang"}>
          <Route path=":cognome" key={"intellettuali_cognome"}>
            <Route path=":nome" key={"intellettuali_nome"}>
              <Route path=":id" element={<><Navbar/><Scheda/><Footer/></>} key={"id_intellettuale"}/>
            </Route>
          </Route>
          {routes.map(route => (
          <Route path={route.path_en} element={<ContenutiContainer/>} key={route.path_en}/>
          ))
          }
          <Route path="alphabetical_list" element={<ElencoContainer/>} key={"alphabetical_list"}/>
          <Route path="research_guide" element={<RicercaContainer/>} key={"research"}/>
          {/* <Route path="video-playlist" element={<><VideoPlaylist/><Footer/></>} key={"videoplaylist"}/> */}
        </Route>
      </Route>
    </Routes>
  )
}
export default routes;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <MyRoutes/>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

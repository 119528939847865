import React from 'react'
import './modal.css';


function Modal(props) {
    const contenuto = props.modalContent;

  return (
    <div className='int__modal_home-background'>
        <div className='int__modal_home-container'>
            <div className='int__modal_home-title-container'>
                <div className='int__modal_home-title'>
                    <h1>{contenuto.titolo}</h1>
                </div>
                <button className='int__modal_home-closeButton' onClick={() => props.closeModal(false)}>X</button>
            </div>
            <div className='int__modal_home-body' dangerouslySetInnerHTML={{__html: contenuto.testo}}></div>
        </div> 
    </div>
  )
}

export default Modal
import React, {useState, useEffect, useRef} from "react";
import './maincontainer.css';
import Content from "../content/Content";
import Sidebar from "../sidebar/Sidebar";
import Modal from "../modal/Modal";

const Maincontainer = (props) => {
    const [mapEvent, setMapEvent] = useState([]);
    const intellettuale = props.intellettuale;
    const mapRef = useRef(null);
    const mobilitaRef = useRef(null);
    const familiariRef = useRef(null);
    const viteconnesseRef = useRef(null);
    const entiRef = useRef(null);
    const referenzeRef = useRef(null);
    const relazioniRef = useRef(null);
    const {schedaIntellettuale}=props.schedaIntellettuale
    const [openModal, setOpenModal] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    
    const refs = useRef({viteconnesseRef, mapRef, mobilitaRef, familiariRef, entiRef, referenzeRef, relazioniRef})

    const [viteConnessePresent, setViteConnessePresent] = useState(false);
    const [timelinePresent, setTimelinePresent] = useState(false);
    const [mapPresent, setMapPresent] = useState(false);

    
    return(
        <>
        <div className="int__maincontainer">
            <div className="int__maincontainer-horiz">
                {schedaIntellettuale 
                    ? <>
                    <Sidebar 
                        {...{schedaIntellettuale:schedaIntellettuale, 
                            setMapEvent:setMapEvent, 
                            searchString:props.searchString, 
                            setSearchString:props.setSearchString,
                            timelinePresent:timelinePresent,
                            mapPresent:mapPresent,
                            setModalContent:setModalContent, 
                            setOpenModal:setOpenModal,
                            viteConnessePresent:viteConnessePresent,
                            setTimelinePresent:setTimelinePresent}}
                        ref={refs}
                        /> 
                    <Content 
                        {...{
                            intellettuale:intellettuale,
                            schedaIntellettuale:schedaIntellettuale, 
                            setMapEvent:setMapEvent, 
                            mapEvent:mapEvent, 
                            searchString:props.searchString,
                            setSearchString:props.setSearchString, 
                            setModalContent:setModalContent, 
                            setOpenModal:setOpenModal,
                            setMapPresent:setMapPresent,
                            setViteConnessePresent: setViteConnessePresent}}
                        ref={refs}/> 
                    </>
                    : <h1> Loading </h1>}
            </div>
        </div>
        {openModal && <Modal closeModal={setOpenModal} modalContent={modalContent}/>}
        </>
    )
}

export default Maincontainer
import axios from "axios";
import React, {useState, useEffect} from "react";
import './gallery.css';

import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import baseURL from '../../config';


const Gallery = (props) => {
    const [showGallery, setShowGallery] = useState(false);
    const schedaIntellettuale = props.schedaIntellettuale;
    const [gallery, setGallery] = useState([]);
    const [mainImage, setMainImage] = useState(null);

    const compareImages = (a,b) => {
        if (a.ordine < b.ordine) return -1;
        if (a.ordine > b.ordine) return 1;
        return 0;
    }

    useEffect(() => {
        if (schedaIntellettuale) {
          const immaginiURL = baseURL+`/immagini?filter={"where": {"and" : [{"idScheda": ${schedaIntellettuale.id}}, {"pubblica" : "true"}]}}`
          axios.get(immaginiURL). then((response) => {
            const images = response.data.map((image) => ({
                original: baseURL+"/images/"+image.immagine,
                description: image.descrizione,
                ordine: image.ordine
            }));
            images.sort(compareImages);
            setGallery(images);
            (response.data.length !== 0 && schedaIntellettuale.primaimmagine) ? setMainImage(response.data.find(immagine => immagine.id===schedaIntellettuale.primaimmagine)) : setMainImage(response.data[0]);
        });
        }
    },[schedaIntellettuale])
    
    const handleClick = () => {
        setShowGallery(true);
    };

    const handleCloseClick = () => {
        setShowGallery(false);
    };

    if (gallery.length === 0) return null;
    
    return(
        <>
        <div className="int__gallery-main">
            <div className="int__gallery-title" >
                Gallery
            </div>
            {!mainImage ?
                <div className="int__gallery-image-box" onClick={handleClick}> 
                    <img src={gallery[0].original} alt="Gallery" className="int__gallery-image"></img>
                </div> 
                : 
                <div className="int__gallery-image-box" onClick={handleClick}> 
                    <img src={baseURL+"/images/"+mainImage.immagine} alt="Gallery" className="int__gallery-image"></img>
                </div>
            }
        </div>
        <Dialog
        fullWidth={true}
            open={showGallery}
            onClose={handleCloseClick}
          >
            <DialogTitle>Gallery</DialogTitle>
            <DialogContent>
                <ImageGallery items={gallery} showPlayButton={false} showBullets={true}/>
            </DialogContent>
              
            <DialogActions>
                 
            </DialogActions>
          </Dialog>
        </>

    )
}

export default Gallery
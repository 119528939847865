import React, {useEffect, useState, useRef } from 'react';
import './ricerca.css'
import {
    Outlet,
    Link, useParams
  } from "react-router-dom";
import axios from 'axios';
import baseURL from '../../config';
import { Trans, useTranslation } from "react-i18next";
import Modal from '../../containers/modal/Modal';
import RicercaAvanzata from './RicercaAvanzata';
import RicercaTestuale from './RicercaTestuale';


const Ricerca = () => {
    const { t, i18n } = useTranslation();
    const lang = i18n.language.substring(0,2);

    return (
      <>
      <div className="int__contenuti-navbar">
        <div className="int__contenuti-navbar-horiz">
            <div className="int__contenuti-navbar-left-container">
                <div className="int__contenuti-navbar-left-container-box">
                  {lang==="it" ? 
                    <Link to={`/${lang}/avvertenze_ricerca`} key={"avvertenzericerca"}>
                        <button className="int__contenuti-pages-button">
                            <img src={require("../../assets/left2.png")} alt="Left" className="int__contenuti-icons"></img>
                        </button>
                        <div className='int__contenuti-navbar-title'>{t("Avvertenze per la ricerca")} </div>
                    </Link>
                    :
                    <Link to={`/${lang}/notes_on_research`} key={"avvertenzericerca"}>
                        <button className="int__contenuti-pages-button">
                            <img src={require("../../assets/left2.png")} alt="Left" className="int__contenuti-icons"></img>
                        </button>
                        <div className='int__contenuti-navbar-title'>{t("Avvertenze per la ricerca")} </div>
                    </Link>
                  }
                </div>
            </div>
            <div className="int__contenuti-navbar-right-container">
                <div className="int__contenuti-navbar-right-container-box">
                  {lang==="it" ? 
                    <Link to={`/${lang}/fotografie`} key={"fotogragie"}>
                        <button className="int__contenuti-pages-button">
                            <img src={require("../../assets/right2.png")} alt="Right" className="int__contenuti-icons"></img>
                        </button>
                        <div className='int__contenuti-navbar-title'>{t("Fotografie")}</div>
                    </Link>
                    :
                    <Link to={`/${lang}/photos`} key={"photos"}>
                        <button className="int__contenuti-pages-button">
                            <img src={require("../../assets/right2.png")} alt="Right" className="int__contenuti-icons"></img>
                        </button>
                        <div className='int__contenuti-navbar-title'>{t("Fotografie")}</div>
                    </Link>
                  }
                </div>
            </div>
        </div>
      </div>
      <div className="int__ricerca-global-container-vert">
          <div className='int__ricerca-global-container-horiz'>
              <div className="int__contenuti-title-name">
                {t("Guida alle ricerche")}
              </div>
              <div className="int__ricerca-contenuti-testo">
                <Trans i18nKey="testoRicerca">
                  Gli utenti del sito possono compiere autonome ricerche su testi, elenchi e data base. Complessivamente i due elenchi di mobilità, aperti ad inserimenti, danno conto di una dimensione del fenomeno, nelle sue conseguenze, molto più ampia del gruppo indagato a livello approfondito nelle <i>{"Vite in movimento"}</i>. Dei familiari in mobilità si danno alcune notizie negli approfondimenti del loro familiare IF, e talvolta compaiono nelle immagini archiviate delle
                </Trans>
                {lang==="it" ? 
                    <Link to={`/${lang}/fotografie`} key={"fotogragie"}>
                        <a className='int__ricerca_link_foto'> gallery fotografiche.</a>
                    </Link>
                    :
                    <Link to={`/${lang}/photos`} key={"photos"}>
                        <a className='int__ricerca_link_foto'> photographs galleries.</a>
                    </Link>
                  }
              </div>
          </div>
      </div>
      <div className='int__ricerca-global-container-vert'>
        <div className='int__ricerca-global-container-horiz'>
          <div className='int__ricerca-section-container'>
            <div className="int__ricerca-title-container-title">
                {lang==="it" ? 
                  <Link to={`/${lang}/elenco_intellettuali`} key={"cercaintellettuali"}>
                    {t("Cerca nome IF")}
                  </Link>
                  :
                  <Link to={`/${lang}/alphabetical_list`} key={"cercaintellettuali"}>
                    {t("Cerca nome IF")}
                  </Link>
                }
            </div>
            <div className="int__ricerca-title-container-title">
                {lang==="it" ? 
                  <Link to={`/${lang}/reti_familiari`} key={"cercafamiliari"}>
                    {t("Cerca nome fra i familiari in mobilità")}
                  </Link>
                  :
                  <Link to={`/${lang}/family_networks`} key={"cercafamiliari"}>
                    {t("Cerca nome fra i familiari in mobilità")}
                  </Link>
                }
            </div>
          </div>
          <div className='int__ricerca-section-container'>
            <div className="int__ricerca-title-name">
                {t("Ricerca testuale libera sul sito")}
            </div>
            <div className="int__contenuti-testo">
              {t("E’ possibile trovare un termine richiesto su tutte le pagine del sito. È necessario ripetere la ricerca per parole chiave a ogni singola pagina a cui punta la ricerca. Nei PDF le occorrenze di tale termine risultano evidenziate.")}
            </div>
            <RicercaTestuale/>
          </div>
          <div className='int__ricerca-section-container'>
            <div className="int__ricerca-title-name">
                {t("Ricerca avanzata nel database IF")}
            </div>
            <div className="int__contenuti-testo">
              {t("È stata inserita una ricerca avanzata per filtri di genere, ambito disciplinare e professionali, con elenco a tendina. Lo stesso nominativo può risultare in più parametri.")}
            </div>
            <RicercaAvanzata/>
          </div>
        </div>
      </div>
    </>
  )
}

export default Ricerca
import React, { memo } from 'react';
import { Handle } from 'reactflow';
import "./styles.css";

export default memo(({ data }) => {
  return (
    <div className="int__grey-triangle-node">
      <Handle
        type="target"
        position="top"
        id="input"
        style={{  width:0, height:0, top: 10, border:"none",backgroundColor: "transparent"}}
      />
      <div
      style={{
        marginTop: "14px",
        marginLeft: "-90px"
      }}>
        <input className="nodrag" type="text" defaultValue={data.label} onChange={event => data.label=event.target.value} style={{textAlign: "center", width:170, fontSize:13}}/>
      </div>
      <Handle
        type="target"
        position="left"
        id="input_left"
        style={{  width:0, height:0, top: 10, border:"none", backgroundColor: "transparent", top: 26, left:-90 }}
      />
      <Handle
        type="source"
        position="right"
        id="output_right"
        style={{  width:0, height:0, top: 10, border:"none", backgroundColor: "transparent", top: 26, right:-80 }}
      />
      <Handle
        type="source"
        position="bottom"
        id="output"
        style={{  width:0, height:0, bottom: -26, border:"none",backgroundColor: "transparent"}}
      />
    </div>
  );
});

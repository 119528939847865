import React, { memo } from 'react';
import { Handle } from 'reactflow';
import "./styles.css";

export default memo(({ data }) => {
  return (
    <div className="int__dot-node" style={{
      backgroundColor: "transparent",
      borderRadius: "10px"
    }}>
      <Handle
        type="target"
        position="top"
        id="input"
        style={{ width:0, height: 0, left:76, top: 15, border:"none", backgroundColor: "transparent" }}
      />
      {/* <div
      style={{
        marginTop: "5px",
        marginLeft: "-85px"
      }}>
        <input className="nodrag" type="text" defaultValue={data.label} disabled style={{textAlign: "center", width:170, fontSize:13}}/>
      </div> */}
      <Handle
        type="target"
        position="left"
        id="input_left"
        style={{  width:20, top: 20, left:67, border:"none", backgroundColor: "transparent" }}
      />
      <Handle
        type="source"
        position="right"
        id="output_right"
        style={{  width:20, top: 20, right:67, border:"none", backgroundColor: "transparent" }}
      />
      <Handle
        type="source"
        position="bottom"
        id="output"
        style={{ width:0, height: 0, left:76, bottom: 13, border:"none", backgroundColor: "transparent" }}
      />
    </div>
  );
});

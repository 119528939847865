import React, { useState, useEffect, useCallback } from "react";
import './body.css';
import { Document, Page, pdfjs } from "react-pdf";
import axios from "axios";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { useDebounce } from "use-debounce";
import { usePdfTextSearch } from "./pdfTextSearch";
import baseURL from '../../config';
import { useTranslation } from 'react-i18next';


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
/* pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js'; */



const Body = (props) => {
    const [fileScheda, setFileScheda] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
      if (props.fileschedaattivo) {
        const fileSchedeURL = baseURL+`/fileSchede/${props.fileschedaattivo}`;
        axios.get(fileSchedeURL). then((response) => {
          setFileScheda(response.data);
        }).catch((error) => {
          console.error("error:", error);
          setFileScheda(null);
        });
      } else {
        setFileScheda(null);
      };
    },[props,t]);

    const DownloadPdfButton = (props) => {
      const donwloadPdf = () =>{
          const link = document.createElement("a");
          const filename = props.fileScheda.nomefile.substring(0, props.fileScheda.nomefile.lastIndexOf("."))+".pdf";
          link.download = props.fileScheda.id;
          link.href = baseURL+"/files/"+filename;
          link.click();
      }
      return (
          <button className="int__body-pdf-pages-button" onClick={donwloadPdf}>
              {t("Scarica PDF")}
          </button>
      )
    }

    const SearchPdfResponsive = (props) => {
      const [string, setString] = useState(props.searchString)
      const handleSubmit = () => {
        props.setSearchString(string)
      }
      return(
          <div className="int__pdf-search-resp-box-container">
              <div className="int__pdf-search-resp-box">
                  <p> {t("Cerca nel PDF")}: </p>
                  <form onSubmit={handleSubmit}>
                    <input
                        value={string}
                        onChange={(e) => setString(e.target.value)}
                        type="text" />
                  </form>
              </div>
          </div>
      )
    }

    return(
        <div className="int__body-main">
            {fileScheda ? <>
                          <SearchPdfResponsive {...{searchString:props.searchString, 
                                          setSearchString: props.setSearchString}}/>
                          <SinglePage {...{fileScheda:fileScheda, 
                                           defaultSearchString:props.searchString, 
                                           setOpenModal:props.setOpenModal, 
                                           setModalContent:props.setModalContent}}/> 
                          <DownloadPdfButton {...{fileScheda:fileScheda}}/>
                          </>
                          : 
                          <div className="int__pdf-title"><p> {t("PDF ancora non disponibile")}</p></div>}
                          
        </div>
    )
}

function highlightPattern(text, pattern) {
  const regEscape = v => v.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  const splitText = pattern ? text.split(new RegExp(regEscape(pattern), "ig")) : "";

  if (splitText.length <= 1) {
    return text;
  }

  const matches = pattern ? text.match(new RegExp(regEscape(pattern), "ig")) : "";

  return splitText.reduce((arr, element, index) => (matches[index] 
    ? [...arr, element, <mark key={index}>{matches[index]}</mark>,] 
    : [...arr, element]), 
    []);
}

function SinglePage(props) {
    const fileScheda = props.fileScheda;
    const fileName = fileScheda.nomefile.substring(0, fileScheda.nomefile.lastIndexOf(".")) + ".pdf";
    const pdf = baseURL+"/files/"+fileName
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1); //setting 1 to show fisrt page
    const [screenSize, setScreenSize] = useState(window.innerWidth);

    const [searchString, setSearchString] = useState(null);
    const [debouncedSearchString] = useDebounce(searchString, 250);
    const { t } = useTranslation();

    const setOpenModal = props.setOpenModal;
    const setModalContent = props.setModalContent;

    const searchResults = usePdfTextSearch(pdf, debouncedSearchString);

    useEffect(() => {
      setSearchString(props.defaultSearchString);
    },[props.defaultSearchString]);

    const textRenderer = useCallback(
      (textItem) => {
        if (!textItem) return null;
        return highlightPattern(textItem.str, debouncedSearchString);
      },
      [debouncedSearchString]
    );

    useEffect(() => {
      window.addEventListener("resize", () => {
          setScreenSize(window.innerWidth);
      });
      return () => {
          window.removeEventListener("resize", () => {
              setScreenSize(window.innerWidth);
          })
      }
    }, [window.innerWidth]);

    let resultText = "Risultati trovati alle pagine:";
    
    if (searchResults.length === 0) {
      resultText = "no results found";
    }

    if (!searchString) {
      resultText = null;
    }

    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
      setPageNumber(1);
    }
  
    function changePage(offset) {
      setPageNumber(prevPageNumber => prevPageNumber + offset);
    }
  
    function previousPage() {
      changePage(-1);
    }
  
    function nextPage() {
      changePage(1);
    }

    function firstPage() {
      setPageNumber(1);
    }
  
    function lastPage() {
      setPageNumber(numPages);
    }
  
    function setPage(page) {
      setPageNumber(page);
    }

    return (
      <>        
        {/* <div className="int__pdf-title">
          <p> {t("Vite in movimento")}</p>
        </div> */}
        {searchResults.length !== 0
          ?<div className="int__pdf-search-results-container" key={resultText}>
            <p>{t(resultText)}</p>
            <div className="int__pdf-search-results" key={"search_results_box"}>
              {searchResults.map((result) => <button className="int__pdf-search-results-pages" onClick={()=>setPage(result)} key={result}>{result}</button>)}
            </div>
          </div>
          : null }
          
          {/* <div className="int__pdf-search-box">
            <p> {t("Cerca nella scheda")}: </p>
            <input
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
            type="text" />
          </div> */}
          
        <Document
          file={pdf}
          onLoadSuccess={onDocumentLoadSuccess}
          renderMode="svg"
          className="int__pdf-document"
        >
          {pageNumber>1 ?
          <div className="int__pdf-pages-button-container">
            <button className="int__pdf-pages-button" disabled={pageNumber <= 1} onClick={previousPage}>
              <img src={require("../../assets/left1.png")} alt="Left" className="int__pdf-icons"></img>
            </button>
            <button className="int__pdf-lastpages-button" disabled={pageNumber <= 1} onClick={firstPage}>
              <img src={require("../../assets/left1.png")} alt="Left" className="int__pdf-icons"></img>
              <img src={require("../../assets/left1.png")} alt="Left" className="int__pdf-icons"></img>
            </button>
          </div>
            : null}
          <Page height={Math.max(Math.min(1280,screenSize*80/100), 475)} pageNumber={pageNumber} customTextRenderer={textRenderer} />
          {pageNumber<numPages ?
          <div className="int__pdf-pages-button-container">
            <button className="int__pdf-pages-button" disabled={pageNumber >= numPages} onClick={nextPage}>
              <img src={require("../../assets/right1.png")} alt="Right" className="int__pdf-icons"></img>
            </button>
            <button className="int__pdf-lastpages-button" disabled={pageNumber >= numPages} onClick={lastPage}>
              <img src={require("../../assets/right1.png")} alt="Right" className="int__pdf-icons"></img>
              <img src={require("../../assets/right1.png")} alt="Right" className="int__pdf-icons"></img>
            </button>
          </div>
          : null}
        </Document>
        <div className="int__pdf-pages" >
          <p>
            {t("Pagina")} {pageNumber || (numPages ? 1 : "--")} {t("di")} {numPages || "--"}
          </p>         
        </div>
        {/* <div className="int__pdf-buttons">
          {fileScheda ? <DownloadPdfButton {...{fileScheda:fileScheda}}/> : null}
          <ArchiviAbbreviazioniButton {...{setOpenModal:setOpenModal, setModalContent:setModalContent}}/>
        </div> */}
      </>
    );
}

export default Body
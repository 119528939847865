import React, { memo } from 'react';
import { Handle } from 'reactflow';
import "./styles.css";

export default memo(({ data }) => {
  return (
    <div className="int__circle-node"
      style={{
        backgroundColor: "#E5E5E5",
        padding: "14px",
        borderRadius: "50px"
      }}
    >
      <Handle
        type="target"
        position="top"
        id="input"
        style={{ width:0, height: 0, top: 1, border:"none", backgroundColor: "transparent" }}
      />
      <div
      style={{
        marginTop: "-12px",
        marginLeft: "-90px"
      }}>
        <input className="nodrag" type="text" defaultValue={data.label} disabled style={{textAlign: "center", width:170, fontSize:13}}/>
      </div>
      <Handle
        type="target"
        position="left"
        id="input_left"
        style={{  width:0, height: 0, border:"none", backgroundColor: "transparent", top: 15, left:-75 }}
      />
      <Handle
        type="source"
        position="right"
        id="output_right"
        style={{  width:0, height: 0, border:"none", backgroundColor: "transparent", top: 15, right:-65 }}
      />
      <Handle
        type="source"
        position="bottom"
        id="output"
        style={{ width:0, height: 0, bottom:20, border:"none", backgroundColor: "transparent" }}
      />
    </div>
  );
});

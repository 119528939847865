import React, {useEffect, useState} from "react";
import './header.css';
import { useTranslation } from 'react-i18next';
import {
    Outlet,
    Link
  } from "react-router-dom";
import axios from "axios";
import baseURL from "../../config";
import Modal from "../../containers/modal/Modal";

const Header = (props) => {
    const [openModal, setOpenModal] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [buttonClickedName, setButtonClickedName] = useState("Advisory");
    const { t, i18n } = useTranslation();
    const contenutiId = {
        it: {
            "Advisory": 37,
            "Sostenitori e collaborazioni":4,
            "Credits":35,
            "Licenze d'uso" : 65
            },
        en: {
            "Advisory": 31,
            "Sostenitori e collaborazioni":15,
            "Credits":8,
            "Licenze d'uso" : 66
        }
    }; 

    useEffect(() => {
        let lang = i18n.language.substring(0,2);
        if (lang !== "it") {
            lang = "en"    
        }         
        axios.get(baseURL+"/contenuti/"+contenutiId[lang][buttonClickedName]).then((response) => {
            setModalContent(response.data);
        });
    },[t, buttonClickedName]);

    const handleClick = value => () => {
        setButtonClickedName(value);
        setOpenModal(true);
    }

    const lang = i18n.language.substring(0,2);

    return(
        <div className="int__header-container">
            <div className="int__header-title-container" onClick={()=> props.setToggleMenu(true)}>
                <div className="int__header-title-main">
                    <p>{t("Intellettuali in fuga")}</p>
                    <p>{t("dall'Italia Fascista")}</p>
                </div>
                <div className="int__header-title-sub">
                    {t("Migranti, esuli e rifugiati per motivi politici e razziali")}
                </div>
                <div className="int__header-title-sub">
                    {t("2a ed. riveduta e ampliata")}
                </div>
                <div className="int__header-title-author">
                    {t("Patrizia Guarnieri")}
                </div>
            </div>
            <div className="int__header-buttons-container">
                {/* <Link to={`/elenco_intellettuali`} key={"elencointellettuali"}>
                    <div className="int__header-form-header-button">
                        <p> {t("Elenco alfabetico")}</p>
                    </div>
                </Link> */}
                {/* <Link to={`/reti_familiari`} key={"retifamiliari"}>
                    <div className="int__header-form-header-button">
                        <p> {t("Reti familiari")}</p>
                    </div>
                </Link> */}
            </div>
            <div className="int__header-form-container">
                {lang =="it" ?
                    <>
                    <Link to={`/${lang}/dicono_di_noi`} key={"dicono_di_noi"}> 
                        <div className="int__header-form-container-button">
                            <p>{t("Dicono di noi")}</p>
                        </div>
                    </Link>
                    <Link to={`/${lang}/cosa_ce_di_nuovo`} key={"novita_aggiornamenti"}>
                        <div className="int__header-form-container-button">
                            <p>  {t("Cosa c'è di nuovo")}</p>
                        </div>
                    </Link>
                    <Link to={`/${lang}/elenco_intellettuali`} key={"elencointellettuali"}>
                        <div className="int__header-form-header-button">
                            <p> {t("Elenco alfabetico")}</p>
                        </div>
                    </Link>
                    <div className="int__header-form-container-button" onClick={handleClick("Advisory")} key={"contatti_button"}  >
                        <p>Advisory board</p>
                    </div>
                    <Link to={`/${lang}/sostenitori`} key={"sostenitori"}>
                        <div className="int__header-form-container-button">
                            <p> {t("Sostenitori e collaborazioni")}</p>
                        </div>
                    </Link>
                    </>
                    :
                    <>
                    <Link to={`/en/about_us`} key={"dicono_di_noi"}> 
                        <div className="int__header-form-container-button">
                            <p>{t("Dicono di noi")}</p>
                        </div>
                    </Link>
                    <Link to={`/en/whats_new`} key={"novita_aggiornamenti"}>
                        <div className="int__header-form-container-button">
                            <p>  {t("Cosa c'è di nuovo")}</p>
                        </div>
                    </Link>
                    <Link to={`/en/alphabetical_list`} key={"elencointellettuali"}>
                        <div className="int__header-form-header-button">
                            <p> {t("Elenco alfabetico")}</p>
                        </div>
                    </Link>
                    <div className="int__header-form-container-button" onClick={handleClick("Contatti")} key={"contatti_button"}  >
                        <p>Advisory board</p>
                    </div>
                    <Link to={`/en/partnerships`} key={"sostenitori"}>
                        <div className="int__header-form-container-button">
                            <p> {t("Sostenitori e collaborazioni")}</p>
                        </div>
                    </Link>
                    </>
                }
            </div>
            <Outlet/>
            {openModal && <Modal closeModal={setOpenModal} modalContent={modalContent}/>}
        </div>
        
    )
}

export default Header
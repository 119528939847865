import React, { useRef, useState, useEffect, useMemo, useCallback } from 'react'
import { TileLayer, LayersControl, MapContainer, Marker, Popup, Polyline } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import './mappa.css';
import { popupContent, popupYear, popupHead, popupText, popupTitle, okText } from "./popupStyles";
import axios from "axios";
import { useMap } from 'react-leaflet/hooks'
import baseURL from '../../config';
import { useTranslation } from 'react-i18next';

const greyicon = L.icon({
    iconSize: [18,30],
    iconAnchor: [7,30],
    popupAnchor: [2,-40],
    iconUrl: require("../../assets/marker-gray-icon.png"),
    shadowUrl: require("../../assets/marker-shadow.png")
});

const pinkicon = L.icon({
    iconSize: [18,30],
    iconAnchor: [7,30],
    popupAnchor: [2,-40],
    iconUrl: require("../../assets/marker-pink-icon.png"),
    shadowUrl: require("../../assets/marker-shadow.png")
});

const redicon = L.icon({
    iconSize: [18,30],
    iconAnchor: [7,30],
    popupAnchor: [2,-40],
    iconUrl: require("../../assets/marker-red-icon.png"),
    shadowUrl: require("../../assets/marker-shadow.png")
});

const limeOptions = { color: 'lime' }

const Mappa = React.forwardRef( (props, ref) => {
    const divRef = ref.current;
    const mapRef = useRef();
    const [datiMobilita, setDatiMobilita] = useState([]);
    const [linePoint, setLinePoint] = useState([]);
    const [bounds, setbounds] = useState([
        [-90, -180],
        [90, 180],
    ]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    const [center, setCenter]=useState([34.38856112555122, -40.6740633991462]);
    const [zoom, setZoom]=useState(window.innerWidth/500);

    const { t } = useTranslation();

    const compareEvents = (a,b) => {
        if (a.anno < b.anno) return -1;
        if (a.anno > b.anno) return 1;
        return 0;
    }


    useEffect(() => {
        const fetchData = async() =>{
            const mobilitaURL = baseURL+`/mobilita?filter={"where": {"idScheda": ${props.idScheda}}}`
            await axios.get(mobilitaURL).then(async (response) => {
                const eventi = response.data;
                eventi.sort(compareEvents);
                setDatiMobilita(eventi);
                const points=[];
                await Promise.all (eventi.map((evento) => {
                    if (evento.latitude) {
                    points.push([evento.latitude, evento.longitude]);
                    }
                }))
                setLinePoint(points);
                setLoading(false);
                if (points.length !== 0) {
                    props.setMapPresent(true);
                }
                
            });
        }
        fetchData();
        
    },[]);

    useEffect(() => {
      if (props.mapEvent.length !== 0){
        setCenter(props.mapEvent);
        setZoom(10);
      }
    },[props.mapEvent]);

    useEffect(() => {
        window.addEventListener("resize", () => {
            setZoom(window.innerWidth/500);
        });
        return () => {
            window.removeEventListener("resize", () => {
                setZoom(window.innerWidth/500);
            })
        }
      }, [window.innerWidth]);

    const HandleEvent = () => {
        const map = useMap();
        map.setView(center, zoom);
        return null
    }

    if (datiMobilita.length===0) return null;
    return(
        <>
            <div className="int__mappa-title" ref={divRef.mapRef}>
                {t("Mappa della linea temporale")}
            </div>
            <div className='int__mappa-container'>
                <MapContainer
                    ref={mapRef}
                    center={center}
                    zoom={zoom}
                    maxZoom={19}
                    minZoom={2}
                    bounceAtZoomLimits={true}
                    maxBoundsViscosity={0.95}
                    maxBounds={bounds}
                    key={`mappa`}
                >
                    <HandleEvent key={`handleEvent`}/>
                    <LayersControl position="topright" key={`layersControl`}>
                    <LayersControl.BaseLayer checked name="OpenStreet" key={`baselayer1`}>
                        <TileLayer
                        noWrap={false}
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        key={`openstreet`}
                        />
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer name="Satellite" key={`baselayer2`}>
                        <TileLayer
                        noWrap={false}
                        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png"
                        key={`satellite`}
                        />
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer name="Dark" key={`baselayer3`}>
                        <TileLayer
                        noWrap={false}
                        url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
                        key={`dark`}
                        />
                    </LayersControl.BaseLayer>
                    </LayersControl>
                    {datiMobilita.map((evento, idx) => {
                        let icon = greyicon;
                        switch (evento.importanza) {
                            case 1: {
                                icon = pinkicon;
                                break; 
                            }
                            case 2: {
                                icon = redicon;
                                break; 
                            }
                            case 3: {
                                icon = redicon;
                                break; 
                            }                            
                            default:
                                break;
                        }
                        if (evento.latitude) {
                            return(
                            <>
                            <Marker position={[evento.latitude, evento.longitude]} icon={icon} closeButton={false} key={`marker-${idx}`}>
                                <Popup key={`popup-${idx}`}>
                                <div style={popupContent} key={`content-${idx}`}>
                                    <div style={popupYear} key={`${evento.anno}-${idx}`}>
                                    {evento.anno}
                                    </div>
                                    {/* <div style={popupHead} key={evento.titoloBreve}>
                                    {evento.titoloBreve}
                                    </div> */}
                                    <div style={popupTitle} key={`${evento.luogoPrincipale}-${idx}`}>
                                    {evento.luogoPrincipale}
                                    </div>
                                    {/* <div style={popupText} key={evento.luoghiEntiStudio}>
                                    {evento.luoghiEntiStudio}
                                    </div> */}
                                    {/* <div style={okText} key={evento.posizioni}>
                                    {evento.posizioni}
                                    </div> */}
                                </div>
                                </Popup>
                            </Marker>
                            </>
                            )
                        }        
                    })}
                <Polyline pathOptions={limeOptions} positions={linePoint} />
                </MapContainer>
            </div>
        </>
    )
});

export default Mappa;



import React, {useEffect} from "react";
import './title.css';
import { useTranslation } from 'react-i18next';

const Title = (props) => {
    const {intellettuale}=props.intellettuale;
    const scheda= props.scheda;    
    const { t } = useTranslation();

    useEffect(()=> {
    }, [t]);

    return(
        <div className="int__title">
            <div className="int__title-container">
                <div className="int__title-box">
                    <div className="int__title-name">
                        {intellettuale.nome} {intellettuale.cognome}
                    </div>
                    <div className="int__title-date">
                        ({intellettuale.dateEsistenza})    
                    </div>
                </div>
                <div className="int_title_subtitle">
                    {scheda.schedaIntellettuale.datazioneNascita} - {scheda.schedaIntellettuale.datazioneMorte}
                </div>
                <div className="int__intestazioni-titlebox">
                    {intellettuale.altreIntestazioni && <>
                    <div className="int__intestazioni-title">
                        {t("Altre intestazioni")}:
                    </div>
                    <div className="int__intestazioni-names">
                        {intellettuale.altreIntestazioni}
                    </div>
                    </>}
                </div>
            </div>
        </div>
        
    )
}

export default Title
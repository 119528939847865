import React from "react";
import './content.css';
import { Body, Mappa, Relazioni, GrafoFamiliari, FamilyTree, ViteConnesse} from "../../components";

import { useTranslation } from 'react-i18next';

const Content = React.forwardRef( (props, ref) => {
    const { t, i18n } = useTranslation();
    const intellettuale = props.intellettuale;
    const schedaIntellettuale=props.schedaIntellettuale;
    const mapEvent = props.mapEvent;
    const setOpenModal = props.setOpenModal;
    const setModalContent = props.setModalContent;
    
    return(
        <div className="int__content">
            {schedaIntellettuale ? 
            <>
            <Body {...{idScheda:schedaIntellettuale.id, fileschedaattivo:schedaIntellettuale.fileschedaattivo, 
                       searchString:props.searchString,
                       setSearchString: props.setSearchString, 
                       setOpenModal:props.setOpenModal, 
                       setModalContent:props.setModalContent}}/> 
            <ViteConnesse {...{ idIntellettuale:schedaIntellettuale.idIntellettuale, 
                                intellettuale:intellettuale,
                                setOpenModal:setOpenModal,
                                setModalContent:setModalContent,
                                setViteConnessePresent: props.setViteConnessePresent}}
                            ref={ref} />

            <Mappa {...{idScheda:schedaIntellettuale.id, 
                        mapEvent:mapEvent, 
                        setMapPresent:props.setMapPresent }} 
                    ref={ref}/>
            </>
            : <h1> Loading </h1>}
            <div className="int__content-approfondimenti">
                {schedaIntellettuale.familiariRelazioni ?
                <div ref={ref.current.familiariRef} className="int__content-approfondimenti-grafo">
                    <div className="int__content-grafo">
                        <div className="int__relazioni-title">
                            {t("Familiari in mobilità")}
                        </div>
                        <br/><br/>    
                        {/* <GrafoFamiliari {...{idIntellettuale:schedaIntellettuale.idIntellettuale, intellettuale:intellettuale,setOpenModal:setOpenModal,setModalContent:setModalContent, title:t("Familiari emigrati")}}/> */}
                        <FamilyTree {...{intellettuale:intellettuale}}/>
                        <br/><br/><br/><br/>
                    </div>                    
                    <Relazioni {...{content:schedaIntellettuale.familiariRelazioni}}/> 
                </div>
                : null}
                {schedaIntellettuale.entiSoccorsoDescrizione ?
                <div ref={ref.current.entiRef}>
                     <Relazioni {...{content:schedaIntellettuale.entiSoccorsoDescrizione, title:t("Enti di soccorso")}}/> 
                </div>
                : null}
                {schedaIntellettuale.referenze ?
                <div ref={ref.current.referenzeRef}>
                     <Relazioni {...{content:schedaIntellettuale.referenze, title:t("Referenze")}}/>
                </div>
                : null}
                {schedaIntellettuale.relazioniAiuto ?
                <div ref={ref.current.relazioniRef}>
                     <Relazioni {...{content:schedaIntellettuale.relazioniAiuto, title:t("Relazioni di aiuto")}}/> 
                </div>
                : null}
            </div>
        </div>        
    )
})

export default Content
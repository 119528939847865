
import React, {useState, useEffect} from 'react'
import './App.css';
import { Header, Navbar, News } from './components';
import Footer from './containers/footer/Footer';
import { Navigate, Outlet, useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';
function App() {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [isValidLang, setIsValidLang] = useState(false);
  let params = useParams();
  const { t, i18n } = useTranslation(); 

  useEffect(() => {
    const isvalid = params.lang=="it" || params.lang=="en" || params.lang==undefined;
    setIsValidLang(isvalid);
    if (isvalid) {
      i18n.changeLanguage(params.lang)
    }
  },[]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page_title: window.location.pathname});
  },[window.location.pathname]);

  return (
    <> 
    {isValidLang ?
      <>
        <div className='int__nav_head-container'>
          <Navbar {...{toggleMenu:toggleMenu, setToggleMenu:setToggleMenu}}/>
          <Header {...{setToggleMenu:setToggleMenu}}/>
        </div>
        <Footer home={true}/>
        <Outlet />
      </>
      : null
    }
  </>
  );
}

export default App;

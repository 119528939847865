const popupContent = {
    textAlign: "center",
    height: "30px",
    marginBottom: "15px"
  };
  const popupYear = {
    fontWeight: "bold",
    color: "grey",
    fontSize: "15px"
  };
  const popupHead = {
    fontWeight: "bold",
    fontSize: "20px"
  };
  
  const popupTitle = {
    fontWeight: "bold",
    fontSize: "16px",
    color: "grey",
    marginTop: "5px"
  };
  
  const popupText = {
    fontSize: "12px",
    marginBottom: "20px"
  };
  
  const okText = {
    fontSize: "11px"
  };
  
  export { popupContent, popupYear, popupHead, popupTitle, popupText, okText };
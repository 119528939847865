import React from "react";
import './searchpdf.css';
import { useTranslation } from 'react-i18next';

const SearchPdf = (props) => {
    const { t } = useTranslation();
    return(
        <div className="int__pdf-search-box-container">
            <div className="int__pdf-search-box">
                <p> {t("Cerca nel PDF")}: </p>
                <input
                    value={props.searchString}
                    onChange={(e) => props.setSearchString(e.target.value)}
                    type="text" />
            </div>
        </div>
    )
}

export default SearchPdf
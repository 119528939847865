import React, {useState, useEffect, useRef} from "react";
import './contenuti.css';
import { useLocation } from 'react-router-dom';
import {
    Outlet,
    Link
  } from "react-router-dom";
import axios from "axios";
import baseURL from '../../config';
import { useTranslation } from 'react-i18next';
import CircularProgress from "./CircularProgress";
import VideoNovita from "./videoPlaylists/VideoNovita";
import VideoAboutUs from "./videoPlaylists/VideoAboutUs";

const FamiliariModal = (props) => {
    const { t, i18n } = useTranslation();
    const contenuto = props.modalContent;
    const lang = i18n.language.substring(0,2)
    return (
        <div className='int__familiari-modal_home-background'>
            <div className='int__familiari-modal_home-container'>
                <div className='int__familiari-modal_home-title-container'>
                    <div className='int__familiari-modal_home-title'>
                        <p>{contenuto.familiare.cognome}, {contenuto.familiare.nome}</p>
                    </div>
                    <button className='int__familiari-modal_home-closeButton' onClick={() => props.closeModal(false)}>X</button>
                </div>
                <div className='int__familiari-modal_home-subtitle'>
                    {t("Familiari")}
                </div>
                <div className='int__familiari-modal_home-body'>
                    {contenuto.viteInMovimentoResult.length!==0 
                        && contenuto.viteInMovimentoResult.map((vita) => 
                            <Link className='int__familiari-modal_elenco-link'
                            to={`/${lang}/${vita.cognome}/${vita.nome}/${vita.id}`}
                            state={{searchString:contenuto.stringToSearch}}
                            key={"intellettuale"+vita.id}>
                            {vita.cognome}, {vita.nome} {vita.dateEsistenza}
                                <div className='int__ricerca-item-contenuto'>
                                    {t("Vite in movimento")}
                                </div>
                            </Link>
                        ) 
                    }
                </div> 
            </div> 
        </div>
    )
}

const RicercaFamiliari = () => {
    const [actualString, setActualString]  = useState("");
    const [searchString, setSearchString] = useState("");
    const [familiari, setFamiliari] = useState([]);
    const [familiariDettagli, setFamiliariDettagli] = useState([]);
    const [intellettualiCollegati, setIntellettualiCollegati]= useState([]);
    const [lettera, setLettera] = useState("A");
    const [familiariSelected, setFamiliariSelected] = useState([]);
    const [familiariResult, setFamiliariResult] = useState([]);
    const [familiariDettagliResult, setFamiliariDettagliResult] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const { t, i18n } = useTranslation();

    const formRef = useRef();

    const compareResultsFamiliari = (a,b) => {
        if (a.cognome < b.cognome) return -1;
        if (a.cognome > b.cognome) return 1;
        if (a.nome < b.nome) return -1;
        if (a.nome > b.nome) return 1;
        return 0;
    }

    useEffect(() => {
        const lingua = i18n.language.substring(0,2) === "it" ? 1 : 2;
        const familiariURL = baseURL+`/familiari`;
        axios.get(familiariURL).then((response) => {
            const results = response.data;
            results.sort(compareResultsFamiliari);
            setFamiliari(results)
            const dettagliURL = baseURL+`/familiaridettagli?filter={"where": {"lingua" : ${lingua}}}`
            axios.get(dettagliURL).then((response) => {
                setFamiliariDettagli(response.data)
            });
        });
    },[t]);
    
    useEffect(() => {
        const fetchData = async () => {
            if (searchString !== "") {
                const lingua = i18n.language.substring(0,2) === "it" ? 1 : 2;
                let familiariFilter="";
                //ricerca sui cognomi e nomi dei familiari
                const familiariURL = baseURL+`/familiari?filter={"where": {"or" : [
                                                                            {"cognome" :{"regexp": "/${searchString}/i"}},
                                                                            {"nome" : {"regexp": "/${searchString}/i"}}
                                                                        ] 
                                                                    }}`;
                await axios.get(familiariURL).then((response) => {
                    const results = response.data;
                    results.sort(compareResultsFamiliari);
                    let familiareIds = results.map(result => result.id);
                    familiariFilter = `{"idfamiliare" : {"inq":[${familiareIds}]}}`;
                    setFamiliariResult(results); 
                });
                
                //ricerca sui dettagli dei familiari
                const dettagliFamiliariURL = baseURL+`/familiaridettagli?filter={"where": {"and" : [
                                                                                            ${familiariFilter},
                                                                                            {"lingua" : ${lingua}}
                                                                                        ]}}`;
                await axios.get(dettagliFamiliariURL).then((response) => {
                    const results = response.data;
                    setFamiliariDettagliResult(results);    
                });                
            }
        }
        const timer = setTimeout(() => {
            fetchData();
        }, 500);
        return () => clearTimeout(timer)
    },[searchString, t]);

    useEffect(() => {
        formRef.current.reset();
        setActualString("");
        setSearchString("");
    },[t]);
    
    const LettereAlfabeto = () => {
        const handleClick = (lettera) =>  {
            const familiariLettera = familiari.filter(familiare =>  familiare.cognome.charAt(0) === lettera)
            setFamiliariSelected (familiariLettera)
            setLettera(lettera)
        }    
        let letteraIniziale = "";
        return (
            <div className='int__elenco-letterecontainer'>
                {familiari.map((familiare, i) => {
                    const primaLettera = familiare.cognome.charAt(0);
                    if (primaLettera !== letteraIniziale) {
                        letteraIniziale = primaLettera;
                        return(
                        <div className='int__elenco-lettere-button' onClick={()=>handleClick(primaLettera)} key={primaLettera}>
                            <p>{primaLettera}</p>
                        </div>
                    )};
                })}
            </div>       
        )
    }
    
    const handleClick = async (familiare) =>{
        const stringToSearch = familiare.cognome + ", "+ familiare.nome;
        const treesURL = baseURL+`/trees`
        await axios.get(treesURL).then((response) => {
            const trees = response.data;
            let treesResult = []
            let viteInMovimentoResult = []
            trees.forEach(tree =>{
                const result = tree.albero.nodes.filter(node => node.data.label === stringToSearch);
                if (result.length !==0)
                treesResult.push(tree)
            })
            let treeUniques = treesResult.filter((element, index) => 
                index === treesResult.findIndex(
                    other => element.idintellettuale === other.idintellettuale 
                )
            );
            treeUniques.forEach( async tree => {
                const intellettualeURL = baseURL+`/intellettuali/${tree.idintellettuale}`;
                await axios.get(intellettualeURL).then((response) => {
                    viteInMovimentoResult.push(response.data);
                    setModalContent({familiare, viteInMovimentoResult, stringToSearch})
                    setOpenModal(true)
                })
            })
        });
    }
    
    if(familiariSelected.length === 0 && familiariResult.length===0 ) return (
        <form ref={formRef}>
            <div className='int__ricerca-familiari-container'>
                <div className='int__ricerca-foto-form-container'> 
                    <p>{t("Cerca nome fra i familiari in mobilità")}</p>
                    <div className='int__ricerca-foto-form'>
                        <input value={searchString}  onChange={(e) => setSearchString(e.target.value)} type="text" />
                    </div>                
                </div>
            </div>
            <LettereAlfabeto/>
        </form>
    )

    if(searchString==="") return (
        <>
        <form ref={formRef}>
            <div className='int__ricerca-familiari-container'>
                <div className='int__ricerca-foto-form-container'> 
                    <p>{t("Cerca nome fra i familiari in mobilità")}</p>
                    <div className='int__ricerca-foto-form'>
                        <input value={searchString}  onChange={(e) => setSearchString(e.target.value)} type="text" />
                    </div>                
                </div>
                <LettereAlfabeto/>
                <div className='int__familiari_elenco'>
                    <div className='int__elenco-elenco-iniziale' key={lettera}>
                        <div className='int__elenco-elenco-lettera' key={lettera+"lettera"}>
                            <p>{lettera}</p>
                        </div>
                        <div className='int__elenco-hr' key={lettera+"hr"}/>
                    </div>
                    { familiariSelected.map((familiare,i)=> {
                        return (
                            <div className="int__familiari_item-result" onClick={()=>handleClick(familiare)}>
                                <div className='int__familiari_item' key={familiare.cognome+i}>
                                    {familiare.cognome}, {familiare.nome}
                                </div>
                                <div className="int__familiari_item-link-ricerca">
                                    <img src={require("../../assets/right1.png")} alt="Right"></img>
                                </div>
                            </div>  
                        ) 
                    })}
                </div>
            </div>
            {openModal && <FamiliariModal closeModal={setOpenModal} modalContent={modalContent}/>}
        </form>
        </>
    )

    if(familiariResult.length===0 && familiariDettagliResult.length === 0) return (
        <>
        <form ref={formRef}>
            <div className='int__ricerca-familiari-container'>
                <div className='int__ricerca-foto-form-container'> 
                    <p>{t("Cerca nome fra i familiari in mobilità")}</p>
                    <div className='int__ricerca-foto-form'>
                        <input value={searchString}  onChange={(e) => setSearchString(e.target.value)} type="text" />
                    </div>                
                </div>
            </div>
        </form>
        <div className='int__foto-ricerca-numerorisultati'>
            {t("Nessun risultato")}
        </div>
        </>
    )

    return (
    <>
        <form  ref={formRef}>
            <div className='int__ricerca-familiari-container'>
                <div className='int__ricerca-foto-form-container'> 
                    <p>{t("Cerca nome fra i familiari in mobilità")}</p>
                    <div className='int__ricerca-foto-form'>
                        <input value={searchString}  onChange={(e) => setSearchString(e.target.value)} type="text" />
                    </div>                
                </div>
            </div>
        </form>
        <div className='int__ricerca-familiari-container'>
            <div className='int__familiari-ricerca-numerorisultati'>
                {t("Risultati")}: {familiariResult.length}
            </div>
            <div className='int__familiari_elenco'>
                {familiariResult.map((familiare,i) => 
                    { 
                        return (
                            <div className="int__familiari_item-result" onClick={()=>handleClick(familiare)} key={"result_familiari"+i}>
                                <div className='int__familiari_item' key={familiare.cognome+i}>
                                    {familiare.cognome}, {familiare.nome}
                                </div>
                                <div className="int__familiari_item-link-ricerca" key={"link"+i}>
                                    <img src={require("../../assets/right1.png")} alt="Right" key={"img"+i}></img>
                                </div>
                            </div>
                        )
                    }) 
                }
            </div>
        </div>
        {openModal && <FamiliariModal closeModal={setOpenModal} modalContent={modalContent}/>}
    </>
  )
}

const RicercaFoto = () => {
    const [actualString, setActualString]  = useState("");
    const [searchString, setSearchString] = useState("");
    const [fotoResult, setFotoResult] = useState([]);
    const [lang, setLang] = useState(null);
    
    const { t, i18n } = useTranslation();

    const formRef = useRef();
    
    const compareResultsSchede = (a,b) => {
        if (a.intellettualiRelation.cognome < b.intellettualiRelation.cognome) return -1;
        if (a.intellettualiRelation.cognome > b.intellettualiRelation.cognome) return 1;
        return 0;
    }
    
    useEffect(() => {
        const fetchData = async () => {
            if (searchString !== "") {
                const lingua = i18n.language.substring(0,2) === "it" ? 1 : 2;
                let fotoFilter="";
                //ricerca su didascalie foto
                const fotoURL = baseURL+`/immagini?filter={"where": {"and" : [
                                                                          {"descrizione" :{"regexp": "/${searchString}/i"}},
                                                                          {"pubblico" : "true"}
                                                                        ] 
                                                                    }}`;
                await axios.get(fotoURL).then((response) => {
                    const results = response.data;
                    let schedeIds = results.map(result => result.idScheda);
                    fotoFilter = `{"id" : {"inq":[${schedeIds}]}}`
                });
                
                //poi si filtrano le schede corrispondenti per lingua
                const schedeURL = baseURL+`/schede?filter={"where": {"and" : [
                                                                            ${fotoFilter},
                                                                            {"lingua" : ${lingua}}, 
                                                                            {"pubblico" : "true"}] 
                                                                            }, 
                                                                            "include": [
                                                                            {"relation": "intellettualiRelation"}
                                                                            ]}`;
                await axios.get(schedeURL).then((response) => {
                    const results = response.data;
                    results.sort(compareResultsSchede);
                    setFotoResult(results);     
                });
            }
        }
        fetchData();
    },[searchString, t]);

    useEffect(() => {
        formRef.current.reset();
        setActualString("");
        setSearchString("");
    },[t]);

    useEffect(() => {
        setLang(i18n.language.substring(0,2));
    },[]);

    const handleSubmit = (event) =>{
        setSearchString(actualString);
        event.preventDefault();
    }

    
    if(searchString==="") return (
        <form onSubmit={handleSubmit} ref={formRef}>
            <div className='int__ricerca-foto-container'>
                <div className='int__ricerca-foto-form-container'> 
                    <p>{t("Cerca nell'archivio fotografico")}</p>
                    <div className='int__ricerca-foto-form'>
                        <input value={actualString}  onChange={(e) => setActualString(e.target.value)} type="text" />                                         
                        <div className='int__ricerca-foto-form-submit'>
                            <input type="submit" value={t("Cerca")} className="int__ricerca-button" />
                        </div>
                    </div>                
                </div>
            </div>
        </form>
    )

    if(fotoResult.length===0) return (
        <>
        <form onSubmit={handleSubmit} ref={formRef}>
            <div className='int__ricerca-foto-container'>
                <div className='int__ricerca-foto-form-container'> 
                    <p>{t("Cerca nell'archivio fotografico")}</p>
                    <div className='int__ricerca-foto-form'>
                        <input value={actualString}  onChange={(e) => setActualString(e.target.value)} type="text" />                                         
                        <div className='int__ricerca-foto-form-submit'>
                            <input type="submit" value={t("Cerca")} className="int__ricerca-button" />
                        </div>
                    </div>                
                </div>
            </div>
        </form>
        <div className='int__foto-ricerca-numerorisultati'>
            {t("Nessun risultato")}
        </div>
        </>
    )

    return (
    <>
        <form onSubmit={handleSubmit} ref={formRef}>
            <div className='int__ricerca-foto-container'>
                <div className='int__ricerca-foto-form-container'> 
                    <p>{t("Cerca nell'archivio fotografico")}</p>
                    <div className='int__ricerca-foto-form'>
                        <input value={actualString}  onChange={(e) => setActualString(e.target.value)} type="text" />                                         
                        <div className='int__ricerca-foto-form-submit'>
                            <input type="submit" value={t("Cerca")} className="int__ricerca-button" />
                        </div>
                    </div>                
                </div>
            </div>
        </form>
        <div className='int__ricerca-foto-elenco'>
            <div className='int__foto-ricerca-numerorisultati'>
                {t("Risultati")}: {fotoResult.length}
            </div>
            <div>
                {fotoResult.lenght!==0 
                    && fotoResult.map((foto) => 
                        <Link className='int__ricerca-item-elenco-link'
                        to={`/${lang}/${foto.intellettualiRelation.cognome}/${foto.intellettualiRelation.nome}/${foto.idIntellettuale}/`} 
                        state={{searchString:searchString}}
                        key={"intellettuale"+foto.id}>
                        {foto.intellettualiRelation.cognome}, {foto.intellettualiRelation.nome} {foto.intellettualiRelation.dateEsistenza}
                            <div className='int__ricerca-item-contenuto'>
                                {t("Gallery")}
                            </div>
                        </Link>
                    ) 
                }
                <Outlet/>
            </div>
        </div>
    </>
  )
}

const Contenuti = () => {
    const [prevContent, setPrevContent] = useState(null);
    const [nextContent, setNextContent] = useState(null);
    const [contenuto, setContenuto] = useState(null);
    const [lang, setLang] = useState(null);
    const location = useLocation();
    const { t, i18n } = useTranslation();

    
    useEffect(() => {
        const lingua = i18n.language.substring(0,2);
        setLang(lingua);
        switch (location.pathname) {
            case `/${lingua}/foreword`:
            case `/${lingua}/presentazione`: {
                if (lingua === "it") {
                    axios.get(baseURL+"/contenuti/1").then((response) => {
                        setContenuto(response.data);
                    });
                    setPrevContent({titolo:"Home", link:"/"});
                    setNextContent({titolo:"Presentazione della 2. edizione", link:`/${lingua}/presentazione_seconda_ed`});
                } else {
                    axios.get(baseURL+"/contenuti/2").then((response) => {
                        setContenuto(response.data);
                    });
                    setPrevContent({titolo:"Home", link:"/"});
                    setNextContent({titolo:"Presentazione della 2. edizione", link:`/${lingua}/foreword_second_ed`});
                }
                break; 
            }
            case `/${lingua}/foreword_second_ed`:
            case `/${lingua}/presentazione_seconda_ed`: {
                if (lingua === "it") {
                    axios.get(baseURL+"/contenuti/51").then((response) => {
                        setContenuto(response.data);
                    });
                    setPrevContent({titolo:"Presentazione", link:`/${lingua}/presentazione`});
                    setNextContent({titolo:"L'emigrazione intellettuale", link:`/${lingua}/emigrazione_intellettuale`});
                } else {
                    axios.get(baseURL+"/contenuti/52").then((response) => {
                        setContenuto(response.data);
                    });
                    setPrevContent({titolo:"Presentazione", link:`/${lingua}/foreword`});
                    setNextContent({titolo:"L'emigrazione intellettuale", link:`/${lingua}/intellectual_emigration`});
                }
                break; 
            }
            case `/${lingua}/intellectual_emigration`:
            case `/${lingua}/emigrazione_intellettuale`: { 
                if (lingua === "it") {
                    axios.get(baseURL+"/contenuti/53").then((response) => {
                        setContenuto(response.data);
                    });
                    setPrevContent({titolo:"Presentazione della 2. edizione", link:`/${lingua}/presentazione_seconda_ed`});
                    setNextContent({titolo:"Gli intellettuali in fuga. Chi erano?", link:`/${lingua}/intellettuali_in_fuga`}); 
                } else {
                    axios.get(baseURL+"/contenuti/54").then((response) => {
                        setContenuto(response.data);
                    }); 
                    setPrevContent({titolo:"Presentazione della 2. edizione", link:`/${lingua}/foreword_second_ed`});
                    setNextContent({titolo:"Gli intellettuali in fuga. Chi erano?", link:`/${lingua}/displaced_intellectuals`});
                }
                break; 
            }
            case `/${lingua}/displaced_intellectuals`:
            case `/${lingua}/intellettuali_in_fuga`:{
                if (lingua === "it") {
                    axios.get(baseURL+"/contenuti/30").then((response) => {
                        setContenuto(response.data);
                    }); 
                    setPrevContent({titolo:"L'emigrazione intellettuale", link:`/${lingua}/emigrazione_intellettuale`});
                    setNextContent({titolo:"Nomi e cognomi", link:`/${lingua}/nomi_e_cognomi`});
                } else {
                    axios.get(baseURL+"/contenuti/11").then((response) => {
                        setContenuto(response.data);
                    }); 
                    setPrevContent({titolo:"L'emigrazione intellettuale", link:`/${lingua}/intellectual_emigration`});
                    setNextContent({titolo:"Nomi e cognomi", link:`/${lingua}/first_names_surnames`});
                }
                break;  
            }
            case `/${lingua}/first_names_surnames`:
            case `/${lingua}/nomi_e_cognomi`:{ 
                if (lingua === "it") {
                    axios.get(baseURL+"/contenuti/40").then((response) => {
                        setContenuto(response.data);
                        setPrevContent({titolo:"Gli intellettuali in fuga. Chi erano?", link:`/${lingua}/intellettuali_in_fuga`});
                        setNextContent({titolo:"Volti, persone e storie", link:`/${lingua}/volti_persone_storie`});
                    }); 
                } else {
                    axios.get(baseURL+"/contenuti/19").then((response) => {
                        setContenuto(response.data);
                    }); 
                    setPrevContent({titolo:"Gli intellettuali in fuga. Chi erano?", link:`/${lingua}/displaced_intellectuals`});
                    setNextContent({titolo:"Volti, persone e storie", link:`/${lingua}/faces_peoples_stories`});
                }
                break;  
            }
            case `/${lingua}/faces_peoples_stories`:
            case `/${lingua}/volti_persone_storie`:{ 
                if (lingua === "it") {
                    axios.get(baseURL+"/contenuti/55").then((response) => {
                        setContenuto(response.data);
                    });
                    setPrevContent({titolo:"Nomi e cognomi", link:`/${lingua}/nomi_e_cognomi`});
                    setNextContent({titolo:"Vite in movimento", link:`/${lingua}/viteinmovimento`});
                } else {
                    axios.get(baseURL+"/contenuti/56").then((response) => {
                        setContenuto(response.data);
                    }); 
                    setPrevContent({titolo:"Nomi e cognomi", link:`/${lingua}/first_names_surnames`});
                    setNextContent({titolo:"Vite in movimento", link:`/${lingua}/livesonthemove`});
                }
                break;  
            }
            case `/${lingua}/livesonthemove`: 
            case `/${lingua}/viteinmovimento`: { 
                if (lingua === "it") {
                    axios.get(baseURL+"/contenuti/29").then((response) => {
                        setContenuto(response.data);
                    });
                    setPrevContent({titolo:"Volti, persone e storie", link:`/${lingua}/volti_persone_storie`});
                    setNextContent({titolo:"Elenco alfabetico", link:`/${lingua}/elenco_intellettuali`}); 
                } else {
                    axios.get(baseURL+"/contenuti/10").then((response) => {
                        setContenuto(response.data);
                    }); 
                    setPrevContent({titolo:"Volti, persone e storie", link:`/${lingua}/faces_peoples_stories`});
                    setNextContent({titolo:"Elenco alfabetico", link:`/${lingua}/alphabetical_list`});
                }
                break; 
            }
            /* case "/elenco":{ 
                if (i18n.language === "it") {
                    axios.get(baseURL+"/contenuti/27").then((response) => {
                        setContenuto(response.data);
                    }); 
                } else {
                    axios.get(baseURL+"/contenuti/20").then((response) => {
                        setContenuto(response.data);
                    }); 
                }
                break; 
            }  */
            case `/${lingua}/family_networks`:
            case `/${lingua}/reti_familiari`:{
                if (lingua === "it") {
                    axios.get(baseURL+"/contenuti/57").then((response) => {
                        setContenuto(response.data);
                    });
                    setPrevContent({titolo:"Elenco alfabetico", link:`/${lingua}/elenco_intellettuali`});
                    setNextContent({titolo:"Work in progress", link:`/${lingua}/work_in_progress`}); 
                } else {
                    axios.get(baseURL+"/contenuti/58").then((response) => {
                        setContenuto(response.data);
                    }); 
                    setPrevContent({titolo:"Elenco alfabetico", link:`/${lingua}/alphabetical_list`});
                    setNextContent({titolo:"Work in progress", link:`/${lingua}/work_in_progress`});
                }
                break; 
            }
            case `/${lingua}/work_in_progress`:{
                if (lingua === "it") {
                    axios.get(baseURL+"/contenuti/39").then((response) => {
                        setContenuto(response.data);
                    });
                    setPrevContent({titolo:"Reti familiari", link:`/${lingua}/reti_familiari`});
                    setNextContent({titolo:"Ricerche e risorse digitali", link:`/${lingua}/ricerche_risorse_digitali`}); 
                } else {
                    axios.get(baseURL+"/contenuti/21").then((response) => {
                        setContenuto(response.data);
                    }); 
                    setPrevContent({titolo:"Reti familiari", link:`/${lingua}/family_networks`});
                    setNextContent({titolo:"Ricerche e risorse digitali", link:`/${lingua}/research_digital_resources`});
                }
                break; 
            }
            case `/${lingua}/research_digital_resources`:
            case `/${lingua}/ricerche_risorse_digitali`:{
                if (lingua === "it") {
                    axios.get(baseURL+"/contenuti/59").then((response) => {
                        setContenuto(response.data);
                    }); 
                    setPrevContent({titolo:"Work in progress", link:`/${lingua}/work_in_progress`});
                    setNextContent({titolo:"Avvertenze per la ricerca", link:`/${lingua}/avvertenze_ricerca`});
                } else {
                    axios.get(baseURL+"/contenuti/60").then((response) => {
                        setContenuto(response.data);
                    }); 
                    setPrevContent({titolo:"Work in progress", link:`/${lingua}/work_in_progress`});
                    setNextContent({titolo:"Avvertenze per la ricerca", link:`/${lingua}/notes_on_research`});
                }
                break; 
            }
            case `/${lingua}/notes_on_research`:
            case `/${lingua}/avvertenze_ricerca`:{
                if (lingua === "it") {
                    axios.get(baseURL+"/contenuti/44").then((response) => {
                        setContenuto(response.data);
                    }); 
                    setPrevContent({titolo:"Ricerche e risorse digitali", link:`/${lingua}/ricerche_risorse_digitali`});
                    setNextContent({titolo:"Guida alle ricerche", link:`/${lingua}/guida_ricerca`});
                } else {
                    axios.get(baseURL+"/contenuti/23").then((response) => {
                        setContenuto(response.data);
                    }); 
                    setPrevContent({titolo:"Ricerche e risorse digitali", link:`/${lingua}/research_digital_resources`});
                    setNextContent({titolo:"Guida alle ricerche", link:`/${lingua}/research_guide`});
                }
                break; 
            }
            case `/${lingua}/photos`:
            case `/${lingua}/fotografie`:{
                if (lingua === "it") {
                    axios.get(baseURL+"/contenuti/16").then((response) => {
                        setContenuto(response.data);
                    }); 
                    setPrevContent({titolo:"Guida alle ricerche", link:`/${lingua}/guida_ricerca`});
                    setNextContent({titolo:"Normative fasciste e normative riparatorie (1925-2000)", link:`/${lingua}/normative`});
                } else {
                    axios.get(baseURL+"/contenuti/17").then((response) => {
                        setContenuto(response.data);
                    }); 
                    setPrevContent({titolo:"Guida alle ricerche", link:`/${lingua}/research_guide`});
                    setNextContent({titolo:"Normative fasciste e normative riparatorie (1925-2000)", link:`/${lingua}/legislation`});
                }
                break; 
            } 
            case `/${lingua}/legislation`:          
            case `/${lingua}/normative`:{
                if (lingua === "it") {
                    axios.get(baseURL+"/contenuti/46").then((response) => {
                        setContenuto(response.data);
                    }); 
                    setPrevContent({titolo:"Fotografie: il nostro archivio", link:`/${lingua}/fotografie`});
                    setNextContent({titolo:"Leggi fascistissime e leggi razziste", link:`/${lingua}/leggi_fascistissime`});
                } else {
                    axios.get(baseURL+"/contenuti/24").then((response) => {
                        setContenuto(response.data);
                    }); 
                    setPrevContent({titolo:"Fotografie: il nostro archivio", link:`/${lingua}/photos`});
                    setNextContent({titolo:"Leggi fascistissime e leggi razziste", link:`/${lingua}/leggi_fascistissime`});
                }
                break;  
            }
            case `/${lingua}/leggi_fascistissime`:{
                if (lingua === "it") {
                    axios.get(baseURL+"/contenuti/41").then((response) => {
                        setContenuto(response.data);
                    }); 
                    setPrevContent({titolo:"Normative fasciste e normative riparatorie (1925-2000)", link:`/${lingua}/normative`});
                    setNextContent({titolo:"Tra accanimento e riparazione", link:`/${lingua}/accanimento_e_riparazione`});
                } else {
                    axios.get(baseURL+"/contenuti/7").then((response) => {
                        setContenuto(response.data);
                    }); 
                    setPrevContent({titolo:"Normative fasciste e normative riparatorie (1925-2000)", link:`/${lingua}/legislation`});
                    setNextContent({titolo:"Tra accanimento e riparazione", link:`/${lingua}/persecution_reparation`});
                }
                break; 
            }
            case `/${lingua}/persecution_reparation`:
            case `/${lingua}/accanimento_e_riparazione`:{
                if (lingua === "it") {
                    axios.get(baseURL+"/contenuti/42").then((response) => {
                        setContenuto(response.data);
                    }); 
                    setPrevContent({titolo:"Leggi fascistissime e leggi razziste", link:`/${lingua}/leggi_fascistissime`});
                    setNextContent({titolo:"Gli anni della Repubblica", link:`/${lingua}/anni_della_repubblica`});
                } else {
                    axios.get(baseURL+"/contenuti/13").then((response) => {
                        setContenuto(response.data);
                    }); 
                    setPrevContent({titolo:"Leggi fascistissime e leggi razziste", link:`/${lingua}/leggi_fascistissime`});
                    setNextContent({titolo:"Gli anni della Repubblica", link:`/${lingua}/years_of_republic`});
                }                
                break; 
            }
            case `/${lingua}/years_of_republic`:
            case `/${lingua}/anni_della_repubblica`:{
                if (lingua === "it") {
                    axios.get(baseURL+"/contenuti/43").then((response) => {
                        setContenuto(response.data);
                    });
                    setPrevContent({titolo:"Tra accanimento e riparazione", link:`/${lingua}/accanimento_e_riparazione`});
                    setNextContent({titolo:"Archivi consultati", link:`/${lingua}/archivi_consultati`});
                } else {
                    axios.get(baseURL+"/contenuti/14").then((response) => {
                        setContenuto(response.data);
                    }); 
                    setPrevContent({titolo:"Tra accanimento e riparazione", link:`/${lingua}/persecution_reparation`});
                    setNextContent({titolo:"Archivi consultati", link:`/${lingua}/consulted_archives`});
                }
                break; 
            }
            case `/${lingua}/consulted_archives`:
            case `/${lingua}/archivi_consultati`:{
                if (lingua === "it") {
                    axios.get(baseURL+"/contenuti/63").then((response) => {
                        setContenuto(response.data);
                    });
                    setPrevContent({titolo:"Gli anni della Repubblica", link:`/${lingua}/anni_della_repubblica`});
                    setNextContent({titolo:"Bibliografia", link:`/${lingua}/bibliografia`});
                } else {
                    axios.get(baseURL+"/contenuti/64").then((response) => {
                        setContenuto(response.data);
                    });
                    setPrevContent({titolo:"Gli anni della Repubblica", link:`/${lingua}/years_of_republic`});
                    setNextContent({titolo:"Bibliografia", link:`/${lingua}/bibliography`});
                }
                break; 
            }
            case `/${lingua}/bibliography`:
            case `/${lingua}/bibliografia`:{
                if (lingua === "it") {
                    axios.get(baseURL+"/contenuti/33").then((response) => {
                        setContenuto(response.data);
                    });
                    setPrevContent({titolo:"Archivi consultati", link:`/${lingua}/archivi_consultati`});
                    setNextContent({titolo:"Credits", link:`/${lingua}/credits`});
                } else {
                    axios.get(baseURL+"/contenuti/12").then((response) => {
                        setContenuto(response.data);
                    }); 
                    setPrevContent({titolo:"Archivi consultati", link:`/${lingua}/consulted_archives`});
                    setNextContent({titolo:"Credits", link:`/${lingua}/credits`});
                }
                break; 
            }
            case `/${lingua}/credits`:{
                if (lingua === "it") {
                    axios.get(baseURL+"/contenuti/35").then((response) => {
                        setContenuto(response.data);
                    });
                    setPrevContent({titolo:"Bibliografia", link:`/${lingua}/bibliografia`});
                    setNextContent({titolo:"Autrici e autori", link:`/${lingua}/autrici_e_autori`});
                } else {
                    axios.get(baseURL+"/contenuti/8").then((response) => {
                        setContenuto(response.data);
                    });
                    setPrevContent({titolo:"Bibliografia", link:`/${lingua}/bibliography`});
                    setNextContent({titolo:"Autrici e autori", link:`/${lingua}/authors`});
                }
                break;
            }
            case `/${lingua}/authors`:
            case `/${lingua}/autrici_e_autori`:{
                if (lingua === "it") {
                    axios.get(baseURL+"/contenuti/5").then((response) => {
                        setContenuto(response.data);
                    });
                    setPrevContent({titolo:"Credits", link:`/${lingua}/credits`});
                    setNextContent({titolo:"Traduttrici e traduttori", link:`/${lingua}/traduttrici_e_traduttori`});
                } else {
                    axios.get(baseURL+"/contenuti/18").then((response) => {
                        setContenuto(response.data);
                    }); 
                    setPrevContent({titolo:"Credits", link:`/${lingua}/credits`});
                    setNextContent({titolo:"Traduttrici e traduttori", link:`/${lingua}/translators`});
                }
                break;
            }
            case `/${lingua}/translators`:
            case `/${lingua}/traduttrici_e_traduttori`:{
                if (lingua === "it") {
                    axios.get(baseURL+"/contenuti/48").then((response) => {
                        setContenuto(response.data);
                    });
                    setPrevContent({titolo:"Autrici e autori", link:`/${lingua}/autrici_e_autori`});
                    setNextContent({titolo:"Ringraziamenti", link:`/${lingua}/ringraziamenti`});
                } else {
                    axios.get(baseURL+"/contenuti/47").then((response) => {
                        setContenuto(response.data);
                    }); 
                    setPrevContent({titolo:"Autrici e autori", link:`/${lingua}/authors`});
                    setNextContent({titolo:"Ringraziamenti", link:`/${lingua}/acknowledgements`});
                }
                break; 
            }
            case `/${lingua}/acknowledgements`:
            case `/${lingua}/ringraziamenti`:{
                if (lingua === "it") {
                    axios.get(baseURL+"/contenuti/34").then((response) => {
                        setContenuto(response.data);
                    });
                    setPrevContent({titolo:"Traduttrici e traduttori", link:`/${lingua}/traduttrici_e_traduttori`});
                    setNextContent({titolo:"Cosa c'è di nuovo", link:`/${lingua}/cosa_ce_di_nuovo`});
                } else {
                    axios.get(baseURL+"/contenuti/25").then((response) => {
                        setContenuto(response.data);
                    }); 
                    setPrevContent({titolo:"Traduttrici e traduttori", link:`/${lingua}/translators`});
                    setNextContent({titolo:"Cosa c'è di nuovo", link:`/${lingua}/whats_new`});
                }
                break;
            }
            case `/${lingua}/whats_new`:
            case `/${lingua}/cosa_ce_di_nuovo`:{
                if (lingua === "it") {
                    axios.get(baseURL+"/contenuti/49").then((response) => {
                        setContenuto(response.data);
                    });
                    setPrevContent({titolo:"Ringraziamenti", link:`/${lingua}/ringraziamenti`});
                    setNextContent({titolo:"Dicono di noi", link:`/${lingua}/dicono_di_noi`});
                } else {
                    axios.get(baseURL+"/contenuti/50").then((response) => {
                        setContenuto(response.data);
                    }); 
                    setPrevContent({titolo:"Ringraziamenti", link:`/${lingua}/acknowledgements`});
                    setNextContent({titolo:"Dicono di noi", link:`/${lingua}/about_us`});
                }
                break;
            }
            case `/${lingua}/about_us`:
            case `/${lingua}/dicono_di_noi`:{
                if (lingua === "it") {
                    axios.get(baseURL+"/contenuti/3").then((response) => {
                        setContenuto(response.data);
                    });
                    setPrevContent({titolo:"Cosa c'è di nuovo", link:`/${lingua}/cosa_ce_di_nuovo`});
                    setNextContent({titolo:"Sostenitori e collaborazioni", link:`/${lingua}/sostenitori`});
                } else {
                    axios.get(baseURL+"/contenuti/6").then((response) => {
                        setContenuto(response.data);
                    });
                    setPrevContent({titolo:"Cosa c'è di nuovo", link:`/${lingua}/whats_new`});
                    setNextContent({titolo:"Sostenitori e collaborazioni", link:`/${lingua}/partnerships`});
                }
                break; 
            }
            case `/${lingua}/partnerships`:
            case `/${lingua}/sostenitori`:{
                if (lingua === "it") {
                    axios.get(baseURL+"/contenuti/4").then((response) => {
                        setContenuto(response.data);
                    });
                    setPrevContent({titolo:"Dicono di noi", link:`/${lingua}/dicono_di_noi`});
                    setNextContent({titolo:"Licenze d'uso", link:`/${lingua}/licenze`});
                } else {
                    axios.get(baseURL+"/contenuti/15").then((response) => {
                        setContenuto(response.data);
                    });
                    setPrevContent({titolo:"Dicono di noi", link:`/${lingua}/about_us`});
                    setNextContent({titolo:"Licenze d'uso", link:`/${lingua}/licenses`});
                }
                break; 
            }
            case `/${lingua}/licenses`:
            case `/${lingua}/licenze`:{
                if (lingua === "it") {
                    axios.get(baseURL+"/contenuti/65").then((response) => {
                        setContenuto(response.data);
                    });
                    setPrevContent({titolo:"Sostenitori e collaborazioni", link:`/${lingua}/partnerships`});
                    setNextContent({titolo:"Home", link:"/"});
                } else {
                    axios.get(baseURL+"/contenuti/66").then((response) => {
                        setContenuto(response.data);
                    });
                    setPrevContent({titolo:"Sostenitori e collaborazioni", link:`/${lingua}/partnerships`});
                    setNextContent({titolo:"Home", link:"/"});
                }
                break; 
            }
            default:
                break;
        }
        window.scrollTo(0, 0);
    },[location, t]);
    
    if (!contenuto) return null;
    return(
        <>
        <div className="int__contenuti-navbar">
            <div className="int__contenuti-navbar-horiz">
                <div className="int__contenuti-navbar-left-container">
                    <div className="int__contenuti-navbar-left-container-box">
                        <Link to={prevContent.link} key={prevContent.titolo}>
                            <button className="int__contenuti-pages-button">
                                <img src={require("../../assets/left2.png")} alt="Left" className="int__contenuti-icons"></img>
                            </button>
                            <div className="int__contenuti-navbar-title">{t(prevContent.titolo)} </div>
                        </Link>
                    </div>
                </div>
                <div className="int__contenuti-navbar-right-container">
                    <div className="int__contenuti-navbar-right-container-box">
                        <Link to={nextContent.link} key={nextContent.titolo}>
                            <button className="int__contenuti-pages-button">
                                <img src={require("../../assets/right2.png")} alt="Right" className="int__contenuti-icons"></img>
                            </button>
                            <div className="int__contenuti-navbar-title">{t(nextContent.titolo)}</div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
        <div className="int__contenuti-testo-container-vert">
            <div className='int__contenuti-testo-container-horiz'>
                <div className="int__contenuti-title-name">
                    {contenuto.titolo}
                </div>
                {location.pathname===`/${lang}/cosa_ce_di_nuovo` || location.pathname===`/${lang}/whats_new`  ? 
                    <>
                    <CircularProgress/>
                    <VideoNovita/>
                    </>
                : null}
                {location.pathname===`/${lang}/dicono_di_noi` || location.pathname===`/${lang}/about_us` ? 
                    <VideoAboutUs/>
                : null}
                <div className="int__contenuti-testo" dangerouslySetInnerHTML={{__html: contenuto.testo}}>
                </div>
            </div>
        </div>
        {location.pathname===`/${lang}/fotografie` || location.pathname===`/${lang}/photos` ? 
        <RicercaFoto/>
        : null}
        {location.pathname===`/${lang}/reti_familiari` || location.pathname===`/${lang}/family_networks` ? 
        <RicercaFamiliari/>
        : null}
        </>
    )
}


export default Contenuti